/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Neighbour } from './Neighbour';
import {
    NeighbourFromJSON,
    NeighbourFromJSONTyped,
    NeighbourToJSON,
} from './Neighbour';

/**
 * 
 * @export
 * @interface AddNodeAndConnectionsToNetworkRequest
 */
export interface AddNodeAndConnectionsToNetworkRequest {
    /**
     * 
     * @type {string}
     * @memberof AddNodeAndConnectionsToNetworkRequest
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AddNodeAndConnectionsToNetworkRequest
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddNodeAndConnectionsToNetworkRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<Neighbour>}
     * @memberof AddNodeAndConnectionsToNetworkRequest
     */
    neighbours: Array<Neighbour>;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeAndConnectionsToNetworkRequest
     */
    centrallyManaged: boolean;
}

/**
 * Check if a given object implements the AddNodeAndConnectionsToNetworkRequest interface.
 */
export function instanceOfAddNodeAndConnectionsToNetworkRequest(value: object): value is AddNodeAndConnectionsToNetworkRequest {
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('neighbours' in value) || value['neighbours'] === undefined) return false;
    if (!('centrallyManaged' in value) || value['centrallyManaged'] === undefined) return false;
    return true;
}

export function AddNodeAndConnectionsToNetworkRequestFromJSON(json: any): AddNodeAndConnectionsToNetworkRequest {
    return AddNodeAndConnectionsToNetworkRequestFromJSONTyped(json, false);
}

export function AddNodeAndConnectionsToNetworkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNodeAndConnectionsToNetworkRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'name': json['name'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'neighbours': ((json['neighbours'] as Array<any>).map(NeighbourFromJSON)),
        'centrallyManaged': json['centrallyManaged'],
    };
}

export function AddNodeAndConnectionsToNetworkRequestToJSON(value?: AddNodeAndConnectionsToNetworkRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'name': value['name'],
        'tags': value['tags'],
        'neighbours': ((value['neighbours'] as Array<any>).map(NeighbourToJSON)),
        'centrallyManaged': value['centrallyManaged'],
    };
}

