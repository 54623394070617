import { UncontrolledAccordion } from "../UncontrolledAccordion";
import { NodeErrorItem } from "./NodeErrorItem";
import { Modal } from "../Modal";
import { Button, ButtonType } from "../buttons/Button";
import React from "react";
import { ResponseErrorMessage } from "../ResponseErrorMessage";
import { GetErrorsFromNode200Response } from "../../../generated/syncroom-api/src";

type Props = {
  response: GetErrorsFromNode200Response | undefined;
  isLoading: boolean;
  error: Error | null;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
};

export const BaseErrorModal = ({
  response,
  isLoading,
  error,
  setIsModalOpen,
  onClose,
}: Props) => {
  return (
    <Modal
      size="large"
      toggleModal={setIsModalOpen}
      title={
        error == null && response != null
          ? `Errors (${response.results.length} of ${response.total})`
          : "error"
      }
      buttons={
        <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
          Cancel
        </Button>
      }
      isLoading={isLoading}
    >
      {error && (
        <ResponseErrorMessage error={error}>
          Error fetching errors
        </ResponseErrorMessage>
      )}
      {response?.results.map((error) => {
        return (
          <UncontrolledAccordion
            key={error.id}
            label={`${error.type} error ${error.id} ${error.rootCauseDetails ?? ""}`}
          >
            <NodeErrorItem nodeError={error} />
          </UncontrolledAccordion>
        );
      })}
    </Modal>
  );
};
