import { ErrorRest, ResponseError } from "../../generated/syncroom-api/src";
import { toast } from "react-toastify";

export class InvalidArgumentError extends Error {
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, InvalidArgumentError.prototype);
  }
}

export const defaultOnError = (error: Error) =>
  handleResponseError(error, (errorMessage) => toast.error(errorMessage));

export const handleResponseError = (
  error: Error,
  onError: (errorMessage: string) => void,
) => {
  if (error.name === "ResponseError") {
    const responseError = error as ResponseError;
    if (responseError.response.status === 403) {
      window.location.replace("/access_denied");
      return;
    }
    if (responseError.response.status === 404) {
      window.location.replace("/not_found");
      return;
    }
    if (!responseError.response.bodyUsed) {
      responseError.response
        .json()
        .then((data: ErrorRest) => {
          onError(data.message || "Unknown error occurred");
        })
        .catch(() => {
          onError("Unknown error occurred");
        });
    }
  } else if (error) {
    onError("Unknown error occurred");
  }
};
