import { ReactNode } from "react";
import { FaX } from "react-icons/fa6";

export enum ChipColor {
  GRAY,
  GREEN,
  RED,
  ORANGE,
  BLUE,
  SLATE,
}

type Props = {
  children?: ReactNode;
  color?: ChipColor;
  leadingIcon?: ReactNode;
  onRemove?: () => void;
  small?: boolean;
};

export const Chip = ({
  children,
  onRemove,
  color = ChipColor.GRAY,
  leadingIcon,
  small = false,
}: Props) => {
  return (
    <div
      className={`inline-flex ${small ? "h-7" : "h-8"} items-center rounded-lg px-1.5 ${getBackgroundColor(color)} ${getTextColor(color)}`}
    >
      {leadingIcon && <span className="text-xl">{leadingIcon}</span>}
      {children && (
        <span className="w-full text-nowrap px-1.5 text-sm font-medium leading-normal">
          {children}
        </span>
      )}
      {onRemove && (
        <button
          type="button"
          className="p-0.5"
          aria-label="Remove"
          onClick={() => onRemove()}
        >
          <FaX />
        </button>
      )}
    </div>
  );
};

const getTextColor = (color: ChipColor) => {
  switch (color) {
    case ChipColor.GRAY:
      return "text-zinc-600";
    case ChipColor.GREEN:
      return "text-emerald-600";
    case ChipColor.RED:
      return "text-red-700";
    case ChipColor.ORANGE:
      return "text-amber-500";
    case ChipColor.BLUE:
      return "text-blue-500";
    case ChipColor.SLATE:
      return "text-primary";
  }
};

const getBackgroundColor = (color: ChipColor) => {
  switch (color) {
    case ChipColor.GRAY:
      return "bg-gray-100";
    case ChipColor.GREEN:
      return "bg-emerald-50";
    case ChipColor.RED:
      return "bg-red-100";
    case ChipColor.ORANGE:
      return "bg-orange-50";
    case ChipColor.BLUE:
      return "bg-sky-100";
    case ChipColor.SLATE:
      return "bg-[#E6E6F0]";
  }
};
