import React, { useEffect, useState } from "react";
import { handleResponseError } from "../util/errors";
import { ErrorBanner } from "./banner/ErrorBanner";

type Props = {
  error: Error;
  children: string;
};
export const ResponseErrorMessage = ({ error, children }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>(error.message);

  useEffect(() => {
    if (error) {
      handleResponseError(error, (msg) => setErrorMessage(msg));
    }
  }, [error]);

  return (
    <ErrorBanner>
      {children} {errorMessage && <span>: {errorMessage}</span>}
    </ErrorBanner>
  );
};
