import { Link, useParams } from "react-router-dom";
import { useNodeInfoQuery } from "../nodes/useNodeInfoQuery";
import { Loader } from "../components/Loader";

export const NodeCrumb = () => {
  const { networkId, nodeId } = useParams();
  if (!nodeId)
    throw new Error("nodeId should be a path param when using this crumb");

  const nodeInfoQuery = useNodeInfoQuery(nodeId);

  if (nodeInfoQuery.isPending) return <Loader />;
  if (nodeInfoQuery.isError) return;

  return (
    <Link to={(networkId ? `/networks/${networkId}` : "") + `/nodes/${nodeId}`}>
      {nodeInfoQuery.data.customName}
    </Link>
  );
};
