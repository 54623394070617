import { FaCircleExclamation } from "react-icons/fa6";

type Props = {
  totalErrors?: number;
};

export const ErrorBar = ({ totalErrors = 0 }: Props) => {
  return (
    <div className="bg-danger-pressed text-danger flex items-center gap-2.5 rounded-lg bg-opacity-10 px-2.5 py-2 text-sm font-bold">
      <div className="bg-danger-pressed rounded-lg bg-opacity-20 p-3 text-base text-red-600">
        <FaCircleExclamation title="Error" />
      </div>
      {totalErrors} errors
    </div>
  );
};
