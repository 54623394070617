import { ErrorMessage } from "formik";
import React, { ReactNode } from "react";

type Props = {
  name: string;
  className?: string;
};

export const FormFieldError = ({ name, className }: Props) => {
  return (
    <ErrorMessage
      name={name}
      render={(errorMessage: ReactNode) => {
        if (typeof errorMessage == "string") {
          return (
            <div
              className={`${className} text-danger mt-1 block pl-1 first-letter:capitalize`}
            >
              {errorMessage}
            </div>
          );
        } else {
          // errorMessage is in some rare cases an array (not valid react element), so ignore
          return null;
        }
      }}
    />
  );
};
