import {
  ConnectedNode,
  Node,
  NodeStatus,
} from "../../../../generated/syncroom-api/src";
import { Button, ButtonSize, ButtonType } from "../../buttons/Button";
import { FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getNodeImage } from "../../../images/svgElements";
import { NodeListAccordionConnection } from "./NodeListAccordionConnection";

type Props = {
  originalNode?: Node;
  connectedNode: ConnectedNode;
  fromNetwork?: boolean;
};

export const NodeListAccordion = ({
  originalNode,
  connectedNode,
  fromNetwork = false,
}: Props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const navigate = useNavigate();
  const { networkId } = useParams();

  const handleClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const navigateToNode = () => {
    const basePath = `/nodes/${connectedNode.id}`;
    if (networkId) {
      navigate(`/networks/${networkId}${basePath}`);
    } else navigate(basePath);
  };

  return (
    <>
      <div className="flex items-center justify-between px-2.5">
        <div className="flex">
          <img
            className="size-16"
            alt={`${connectedNode.type} icon`}
            src={getNodeImage(
              connectedNode.type,
              connectedNode.status === NodeStatus.ERROR,
              false,
            )}
          />
          <div>
            <Button
              type={ButtonType.TEXT_ONLY}
              size={ButtonSize.MEDIUM}
              iconRight={<FaChevronRight />}
              onClick={() => navigateToNode()}
              disabled={connectedNode.id === undefined}
            >
              {connectedNode.customName}
            </Button>
            <p
              className={`px-3.5 text-sm font-bold leading-normal ${connectedNode.id === undefined && "text-slate-300"}`}
            >
              {connectedNode.baseUrl}
            </p>
          </div>
        </div>
        <Button
          type={ButtonType.SECONDARY}
          size={ButtonSize.SMALL}
          iconLeft={isAccordionOpen ? <FaChevronUp /> : <FaChevronDown />}
          onClick={handleClick}
          label={`Show connected nodes of ${connectedNode.customName}`}
        />
      </div>
      {isAccordionOpen && (
        <div className="flex flex-col gap-2">
          {connectedNode.connections.map((connectedNodeConnection) => {
            return (
              <NodeListAccordionConnection
                key={
                  connectedNode.baseUrl + connectedNodeConnection.connectionName
                }
                originalNode={originalNode}
                connectedNode={connectedNode}
                fromNetwork={fromNetwork}
                connectedNodeConnection={connectedNodeConnection}
                networkId={networkId}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
