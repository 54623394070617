/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';
import type { NodeStatus } from './NodeStatus';
import {
    NodeStatusFromJSON,
    NodeStatusFromJSONTyped,
    NodeStatusToJSON,
} from './NodeStatus';

/**
 * Shared properties of a Node
 * @export
 * @interface BaseNode
 */
export interface BaseNode {
    /**
     * The unique identifier of a node in SyncRoom
     * @type {string}
     * @memberof BaseNode
     */
    id: string;
    /**
     * The given name of a node
     * @type {string}
     * @memberof BaseNode
     */
    customName: string;
    /**
     * 
     * @type {NodeType}
     * @memberof BaseNode
     */
    type: NodeType;
    /**
     * 
     * @type {NodeStatus}
     * @memberof BaseNode
     */
    status: NodeStatus;
    /**
     * The URL of the admin interface on the issue tracker itself
     * @type {string}
     * @memberof BaseNode
     */
    adminUrl: string;
    /**
     * Amount of errors currently on the node
     * @type {number}
     * @memberof BaseNode
     */
    totalErrors: number;
    /**
     * The software version that is running on the node
     * @type {string}
     * @memberof BaseNode
     */
    version: string;
    /**
     * The raw software version that is running on the node
     * @type {string}
     * @memberof BaseNode
     */
    rawVersion: string;
}



/**
 * Check if a given object implements the BaseNode interface.
 */
export function instanceOfBaseNode(value: object): value is BaseNode {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('customName' in value) || value['customName'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('adminUrl' in value) || value['adminUrl'] === undefined) return false;
    if (!('totalErrors' in value) || value['totalErrors'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('rawVersion' in value) || value['rawVersion'] === undefined) return false;
    return true;
}

export function BaseNodeFromJSON(json: any): BaseNode {
    return BaseNodeFromJSONTyped(json, false);
}

export function BaseNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseNode {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customName': json['customName'],
        'type': NodeTypeFromJSON(json['type']),
        'status': NodeStatusFromJSON(json['status']),
        'adminUrl': json['adminUrl'],
        'totalErrors': json['totalErrors'],
        'version': json['version'],
        'rawVersion': json['rawVersion'],
    };
}

export function BaseNodeToJSON(value?: BaseNode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'customName': value['customName'],
        'type': NodeTypeToJSON(value['type']),
        'status': NodeStatusToJSON(value['status']),
        'adminUrl': value['adminUrl'],
        'totalErrors': value['totalErrors'],
        'version': value['version'],
        'rawVersion': value['rawVersion'],
    };
}

