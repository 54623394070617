import { UserRoleEnum } from "../../generated/syncroom-api/src";
import { Chip, ChipColor } from "../components/Chip";

type Props = {
  role: UserRoleEnum;
};

export const UserRoleChip = ({ role }: Props) => {
  if (role === UserRoleEnum.NETWORK_ADMIN) {
    return <Chip color={ChipColor.BLUE}>Network admin</Chip>;
  }
  if (role === UserRoleEnum.SYSTEM_ADMIN) {
    return <Chip color={ChipColor.ORANGE}>System admin</Chip>;
  }
};
