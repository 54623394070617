/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Network response object
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    purpose: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    status: NetworkStatusEnum;
    /**
     * Amount of errors currently in the network
     * @type {number}
     * @memberof Network
     */
    totalErrors: number;
    /**
     * Amount of nodes currently in the network
     * @type {number}
     * @memberof Network
     */
    totalNodes: number;
}


/**
 * @export
 */
export const NetworkStatusEnum = {
    OK: 'OK',
    ERROR: 'ERROR'
} as const;
export type NetworkStatusEnum = typeof NetworkStatusEnum[keyof typeof NetworkStatusEnum];


/**
 * Check if a given object implements the Network interface.
 */
export function instanceOfNetwork(value: object): value is Network {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('purpose' in value) || value['purpose'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('totalErrors' in value) || value['totalErrors'] === undefined) return false;
    if (!('totalNodes' in value) || value['totalNodes'] === undefined) return false;
    return true;
}

export function NetworkFromJSON(json: any): Network {
    return NetworkFromJSONTyped(json, false);
}

export function NetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Network {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'purpose': json['purpose'],
        'status': json['status'],
        'totalErrors': json['totalErrors'],
        'totalNodes': json['totalNodes'],
    };
}

export function NetworkToJSON(value?: Network | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'purpose': value['purpose'],
        'status': value['status'],
        'totalErrors': value['totalErrors'],
        'totalNodes': value['totalNodes'],
    };
}

