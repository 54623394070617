import { useAuth } from "react-oidc-context";
import { ReactNode, useEffect } from "react";
import { LoadingPage } from "./components/LoadingPage";

type Props = {
  children: ReactNode;
};

export const AppAuthentication = (props: Props) => {
  const auth = useAuth();
  useEffect(() => {
    if (
      !auth.isLoading &&
      auth.activeNavigator == null &&
      (!auth.isAuthenticated || auth.error != null)
    ) {
      auth.signinRedirect();
    }
  }, [auth]);

  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <LoadingPage />;
  }
  if (auth.error) {
    return (
      <div className="flex justify-center p-6">
        Error during login: {auth.error.message}
      </div>
    );
  }
  if (auth.isAuthenticated) {
    return props.children;
  }
  return <LoadingPage />;
};
