import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../shared/useNodeApi";

export const useNodeInfoQuery = (nodeId: string) => {
  const api = useNodeApi();

  return useQuery({
    queryKey: ["nodeInfo", nodeId],
    queryFn: () =>
      api.getNode({
        nodeId,
      }),
  });
};
