import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
} from "react-select";
import { FaChevronRight } from "react-icons/fa";
import { Toggle } from "../../../components/buttons/Toggle";
import { SelectDropdownIndicator } from "../../../components/select/SelectDropdownIndicator";

type Props = {
  options: (ActionsType | ToggleType)[];
};

export type ActionsType = {
  label: string;
  buttonAction: () => void;
  isDisabled: boolean;
  isToggle: false;
};

export type ToggleType = {
  label: string;
  buttonAction: () => void;
  isDisabled: boolean;
  isManaged: boolean;
  isToggle: true;
};

const Option = (props: OptionProps<ActionsType | ToggleType, false>) => {
  return (
    <components.Option {...props} isDisabled={props.isDisabled}>
      <button
        disabled={props.data.isDisabled}
        className="flex w-full cursor-pointer items-center gap-5 px-5 py-3.5 text-left hover:bg-gray-200 disabled:cursor-default disabled:text-zinc-400 disabled:hover:bg-white"
        onClick={(e) => {
          e.stopPropagation();
          props.data.buttonAction();
        }}
      >
        <p
          className={`flex-grow ${props.data.isToggle ? "text-wrap" : "truncate"}`}
        >
          {props.data.label}
        </p>
        <div className="justify-self-end">
          {props.data.isToggle ? (
            <Toggle setState={() => {}} state={props.data.isManaged} />
          ) : (
            <FaChevronRight className="justify-self-end text-gray-400" />
          )}
        </div>
      </button>
    </components.Option>
  );
};

const DropdownIndicator = (
  props: DropdownIndicatorProps<ActionsType | ToggleType>,
) => <SelectDropdownIndicator props={props} />;

export const ConnectionActionSelect = ({ options }: Props) => {
  return (
    <Select
      components={{ Option, DropdownIndicator }}
      options={options}
      controlShouldRenderValue={false}
      isSearchable={false}
      isOptionDisabled={() => true}
      placeholder="Actions"
      unstyled={true}
      classNames={{
        control: () =>
          `w-40 h-12 border hover:cursor-pointer rounded-lg bg-neutral-lesser_white px-4 border-neutral_lesser_grey`,
        menu: () =>
          `mt-2 border border-neutral_lesser_grey bg-white rounded-lg shadow overflow-visible right-0`,
        option: () => `border-b whitespace-nowrap w-full`,
        indicatorSeparator: () => `bg-gray-200 mx-4 ml-6`,
      }}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: "175%",
          maxHeight: "500px",
          height: "auto",
          overflow: "visible",
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: "inherit",
        }),
      }}
    ></Select>
  );
};
