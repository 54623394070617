import { useUserApi } from "../shared/useUserApi";
import { useMutation } from "@tanstack/react-query";
import { Button, ButtonType } from "../components/buttons/Button";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Roles } from "../shared/Roles";
import { Modal } from "../components/Modal";
import { ErrorBanner } from "../components/banner/ErrorBanner";
import { FormTextInput } from "../components/form/FormTextInput";
import { FormSelectInput } from "../components/form/FormSelectInput";
import { NetworksSelect } from "./NetworksSelect";
import { handleResponseError } from "../util/errors";
import {
  User,
  UserAssignedNetworksInner,
} from "../../generated/syncroom-api/src";

type Props = {
  onClose: () => void;
  onSave: () => void;
  selectedUser?: User;
};

interface FormValues {
  email: string;
  role: Roles;
  networks: UserAssignedNetworksInner[];
}

export const CreateEditUserModal = ({
  onClose,
  onSave,
  selectedUser,
}: Props) => {
  const [error, setError] = useState<string>();
  const [networksInputDisabled, setNetworksInputDisabled] = useState<boolean>(
    selectedUser === undefined,
  );
  const api = useUserApi();

  const networksFieldName = "networks";
  const userValidationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email address is a required field")
      .trim(),
    role: yup
      .string()
      .required("Role is a required field")
      .oneOf([Roles.SYSTEM_ADMIN, Roles.NETWORK_ADMIN]),
    networks: yup
      .array()
      .of(
        yup.object().shape({
          networkId: yup.string().required(),
          networkName: yup.string().required(),
        }),
      )
      .default([]),
  });

  const onSuccess = onSave;
  const onError = (error: Error) =>
    handleResponseError(error, (msg) => setError(msg));

  const createUserMutation = useMutation({
    mutationFn: (values: FormValues) =>
      api.createUser({
        user: {
          email: values.email,
          role: values.role,
          assignedNetworks: values.networks,
        },
      }),
    onSuccess,
    onError,
  });

  const updateUserMutation = useMutation({
    mutationFn: (values: FormValues) =>
      api.updateUser({
        user: {
          email: values.email,
          role: values.role,
          assignedNetworks: values.networks,
        },
      }),
    onSuccess,
    onError,
  });

  return (
    <Formik
      initialValues={{
        email: selectedUser?.email,
        role: selectedUser?.role || Roles.SYSTEM_ADMIN,
        networks: selectedUser?.assignedNetworks || [],
      }}
      validationSchema={userValidationSchema}
      onSubmit={async (values) => {
        setError(undefined);
        const mutation =
          selectedUser === undefined ? createUserMutation : updateUserMutation;
        mutation.mutate(await userValidationSchema.validate(values));
      }}
    >
      {(props) => {
        const { setFieldValue } = props;
        return (
          <Form noValidate>
            <Modal
              title={`${selectedUser !== undefined ? "Edit existing" : "Add new"} user`}
              toggleModal={onClose}
              buttons={
                <div className="flex gap-3">
                  <Button onClick={onClose} type={ButtonType.SECONDARY}>
                    Cancel
                  </Button>
                  <Button buttonType="submit">
                    {selectedUser !== undefined ? "Edit" : "Add"} user
                  </Button>
                </div>
              }
              isLoading={
                createUserMutation.isPending || updateUserMutation.isPending
              }
            >
              <div className="space-y-5">
                {error && <ErrorBanner>{error}</ErrorBanner>}
                <FormTextInput
                  label="Email"
                  name="email"
                  required={true}
                  disabled={selectedUser !== undefined}
                />
                <FormSelectInput
                  label="Role"
                  name="role"
                  required={true}
                  isDisabled={selectedUser !== undefined}
                  options={[
                    { value: Roles.SYSTEM_ADMIN, label: "System admin" },
                    { value: Roles.NETWORK_ADMIN, label: "Network admin" },
                  ]}
                  onChange={async (newValue) => {
                    setNetworksInputDisabled(
                      newValue!.value === Roles.SYSTEM_ADMIN,
                    );
                    await setFieldValue(networksFieldName, []);
                  }}
                />
                <NetworksSelect
                  name={networksFieldName}
                  disabled={networksInputDisabled}
                />
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
