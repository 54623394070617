import React, { useMemo } from "react";

type Props = {
  script: string;
};

export const Script = ({ script }: Props) => {
  const lineCount = useMemo(() => script.split("\n").length, [script]);
  const lines = useMemo(
    () => Array.from({ length: lineCount }, (_, i) => i + 1),
    [lineCount],
  );

  return (
    <pre className="flex h-full pr-3">
      <div className="flex h-full flex-col bg-slate-200 px-3 py-4 text-right">
        {lines.map((count) => (
          <div key={count}>{count}</div>
        ))}
      </div>
      <div className="w-full overflow-x-auto px-6 py-4 text-zinc-600">
        <code>{script}</code>
      </div>
    </pre>
  );
};
