import { Button, ButtonType } from "../../../../components/buttons/Button";
import { useState } from "react";
import { IssueSelectionModal } from "./IssueSelectionModal";
import { NumberedListItem } from "../../../../components/NumberedListItem";
import TestConfigurationIcon from "./TestConfigurationIcon.svg";
import { Issue } from "../../../../../generated/syncroom-api/src";

type Props = {
  sourceNodeId: string;
  connectionId: string;
  versionId: string;
  issueSelection: Issue[];
  onStart: (issues: Issue[]) => void;
};

export const StartTestRun = ({
  sourceNodeId,
  connectionId,
  versionId,
  issueSelection,
  onStart,
}: Props) => {
  const [showIssueSelection, setShowIssueSelection] = useState(false);

  return (
    <>
      <div className="grid h-full grid-cols-2">
        <div className="space-y-8 border-r border-zinc-200 p-8">
          <h1 className="heading-1">Start your Test Run</h1>
          <p className="text-lg leading-relaxed">
            The test configuration runs a simulation of the changes made in a
            draft version. Apply the configuration to one or more entities,
            review the results, and check your version for errors. If the
            outcome meets your expectations, you can publish the version. Ready
            to start testing? Follow the steps below.
          </p>

          <ol className="list-inside space-y-6">
            <NumberedListItem counter={1}>
              Select your test issues.
            </NumberedListItem>
            <NumberedListItem counter={2}>
              Start testing your configuration.
            </NumberedListItem>
            <NumberedListItem counter={3}>
              Save and/or publish after reviewing the test results.
            </NumberedListItem>
          </ol>
        </div>
        <div className="flex flex-col items-center justify-center gap-8 p-8">
          <img src={TestConfigurationIcon} alt="Test Run Configuration Icon" />
          <div className="flex flex-col items-center justify-center gap-4">
            <h3 className="heading-3">Test configuration</h3>
            <p>Start adding test cases to view the configuration in action.</p>
          </div>
          <Button
            type={ButtonType.PRIMARY}
            onClick={() => setShowIssueSelection(true)}
          >
            Select Issues
          </Button>
        </div>
      </div>
      {showIssueSelection && (
        <IssueSelectionModal
          sourceNodeId={sourceNodeId}
          connectionId={connectionId}
          versionId={versionId}
          onClose={() => setShowIssueSelection(false)}
          issueSelection={issueSelection}
          startTestRun={(issues) => {
            setShowIssueSelection(false);
            onStart(
              issues.toSorted((a, b) => {
                if (a.issueUrn > b.issueUrn) {
                  return 1;
                } else if (b.issueUrn > a.issueUrn) {
                  return -1;
                } else {
                  return 0;
                }
              }),
            );
          }}
        ></IssueSelectionModal>
      )}
    </>
  );
};
