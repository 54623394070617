import { Modal } from "../../components/Modal";
import { Form, Formik } from "formik";
import { FormTextInput } from "../../components/form/FormTextInput";
import { FormTextareaInput } from "../../components/form/FormTextareaInput";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import {
  CreateNetwork200Response,
  ResponseError,
} from "../../../generated/syncroom-api/src";
import React, { useState } from "react";
import { useNetworksApi } from "../../shared/useNetworksApi";
import { Button, ButtonType } from "../../components/buttons/Button";
import { handleResponseError } from "../../util/errors";
import { ErrorBanner } from "../../components/banner/ErrorBanner";

type Props = {
  onClose: () => void;
  onSave: (networkId: string) => void;
};

interface FormValues {
  name: string;
  purpose: string;
}

export const CreateNetworkModal = ({ onClose, onSave }: Props) => {
  const [error, setError] = useState<string>();
  const api = useNetworksApi();

  const createNetworkValidationScheme = yup.object({
    name: yup
      .string()
      .required("Network name is a required field")
      .trim()
      .max(255, "Network name can not be longer then 255 characters"),
    purpose: yup.string().required("Purpose is a required field").trim(),
  });

  const createNetworkMutate = useMutation<
    CreateNetwork200Response,
    ResponseError,
    FormValues
  >({
    mutationFn: (values: FormValues) =>
      api.createNetwork({ networkRequestBody: values }),
    onSuccess: (response) => onSave(response.networkId),
    onError: (error) => handleResponseError(error, (msg) => setError(msg)),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        purpose: "",
      }}
      validationSchema={createNetworkValidationScheme}
      onSubmit={async (values) => {
        setError(undefined);
        createNetworkMutate.mutate(
          await createNetworkValidationScheme.validate(values),
        );
      }}
    >
      <Form noValidate>
        <Modal
          toggleModal={() => onClose()}
          title="Create new network"
          buttons={
            <div className="flex gap-3">
              <Button onClick={onClose} type={ButtonType.SECONDARY}>
                Cancel
              </Button>
              <Button buttonType="submit">Add network</Button>
            </div>
          }
          isLoading={createNetworkMutate.isPending}
        >
          <div className="space-y-5">
            {error && <ErrorBanner>{error}</ErrorBanner>}
            <FormTextInput label="Name" name="name" required={true} />
            <FormTextareaInput label="Purpose" name="purpose" required={true} />
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
