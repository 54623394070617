import { NodeTagsEditor } from "../../nodes/detailPage/subcomponents/NodeTagsEditor";
import { FormLabel } from "./FormLabel";

type Props = {
  label: string;
  name: string;
};

export const FormTagsEditor = ({ label, name }: Props) => {
  return (
    <div>
      <label>
        <FormLabel>{label}</FormLabel>
        <NodeTagsEditor name={name} />
      </label>
    </div>
  );
};
