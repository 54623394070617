import { Button, ButtonType } from "../../../components/buttons/Button";
import { ErrorBanner } from "../../../components/banner/ErrorBanner";
import { Modal } from "../../../components/Modal";
import { useState } from "react";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleResponseError } from "../../../util/errors";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
  nodeId: string;
  connectionId: string;
  sourceNodeName: string;
  targetNodeName: string;
};

export const RemoveConnectionModal = ({
  setIsModalOpen,
  onClose,
  nodeId,
  connectionId,
  sourceNodeName,
  targetNodeName,
}: Props) => {
  const [error, setError] = useState<string>();
  const api = useNodeApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const removeConnectionMutation = useMutation({
    mutationFn: () =>
      api.removeBothSidesOfAConnection({
        nodeId,
        connectionId,
      }),
    onSuccess: async () => {
      // Use remove instead of invalidate, to avoid 404 errors, because invalidate triggers a refresh
      queryClient.removeQueries();
      const slicedPath = location.pathname.split("/").slice(0, -4).join("/"); // removes /nodes/{id}/connection/{id} from path
      navigate(slicedPath.length === 0 ? "/networks" : slicedPath);
    },
    onError: (error) => handleResponseError(error, (msg) => setError(msg)),
  });

  return (
    <Modal
      toggleModal={setIsModalOpen}
      title="Remove connection"
      buttons={
        <div className="flex gap-3">
          <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
            Cancel
          </Button>
          <Button onClick={() => removeConnectionMutation.mutate()}>
            Confirm
          </Button>
        </div>
      }
      isLoading={removeConnectionMutation.isPending}
    >
      {error && <ErrorBanner>{error}</ErrorBanner>}
      <p>
        The connection '{sourceNodeName} to {targetNodeName}' is currently
        managed in SyncRoom. You’re about to disable this function and move
        management to the external admin environment. Are you sure?
      </p>
    </Modal>
  );
};
