import { FaCircleExclamation } from "react-icons/fa6";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};
export const WarningBanner = ({ children }: Props) => {
  return (
    <div className="flex gap-4 rounded-lg border border-l-8 border-amber-300 border-l-amber-500 bg-amber-50 p-4">
      <div className="flex items-center text-xl text-amber-500">
        <FaCircleExclamation />
      </div>
      <div className="leading-normal">{children}</div>
    </div>
  );
};
