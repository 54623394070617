import { NodesAddedStepBase } from "../../../components/NodesAddedStepBase";
import { AddNodeAndConnectionsToNetwork200ResponseInner } from "../../../../generated/syncroom-api/src";
import { useFormikContext } from "formik";
import { FormValues } from "./AddNodeModal";

type Props = {
  results: AddNodeAndConnectionsToNetwork200ResponseInner[];
  networkId: string;
};

export const NodesAddedStepForAddNodeModal = ({
  results,
  networkId,
}: Props) => {
  const formContext = useFormikContext<FormValues>();
  return (
    <NodesAddedStepBase
      results={results}
      networkId={networkId}
      nodeName={formContext.values.name}
      nodeUrl={formContext.values.url}
      tags={formContext.values.tags}
      centrallyManaged={formContext.values.centrallyManaged}
    />
  );
};
