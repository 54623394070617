import { SidebarItem } from "./SidebarItem";
import { useAuth } from "react-oidc-context";
import { LogoutIcon } from "../../../icons/menu/LogoutIcon";

export const LogoutItem = () => {
  const auth = useAuth();
  return (
    <SidebarItem
      icon={<LogoutIcon />}
      text="Log out"
      onClick={() => {
        auth.signoutRedirect({
          post_logout_redirect_uri: window.location.origin,
        });
      }}
    />
  );
};
