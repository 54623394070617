import { Field } from "formik";
import { TextareaHTMLAttributes } from "react";
import { FormLabel } from "./FormLabel";
import { FormFieldError } from "./FormFieldError";

type Props = TextareaHTMLAttributes<string> & {
  label: string;
  name: string;
};

export const FormTextareaInput = ({
  name,
  label,
  rows = 6,
  ...rest
}: Props) => {
  return (
    <div>
      <label>
        <FormLabel>{label}</FormLabel>
        <Field
          {...rest}
          as="textarea"
          className="bg-neutral-lesser_white focus:border-primary focus:ring-primary-lesser_purple min-h-16 w-full rounded-lg border border-slate-200 font-normal placeholder:text-gray-400 focus:outline-none focus:ring disabled:text-gray-400"
          type="text"
          rows={rows}
          name={name}
        />
      </label>
      <FormFieldError name={name} />
    </div>
  );
};
