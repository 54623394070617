/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Neighbour } from './Neighbour';
import {
    NeighbourFromJSON,
    NeighbourFromJSONTyped,
    NeighbourToJSON,
} from './Neighbour';

/**
 * 
 * @export
 * @interface AddNodeAndConnectionsToNetwork200ResponseInner
 */
export interface AddNodeAndConnectionsToNetwork200ResponseInner {
    /**
     * 
     * @type {Neighbour}
     * @memberof AddNodeAndConnectionsToNetwork200ResponseInner
     */
    neighbour: Neighbour;
    /**
     * 
     * @type {string}
     * @memberof AddNodeAndConnectionsToNetwork200ResponseInner
     */
    result: AddNodeAndConnectionsToNetwork200ResponseInnerResultEnum;
    /**
     * 
     * @type {string}
     * @memberof AddNodeAndConnectionsToNetwork200ResponseInner
     */
    reason?: string;
}


/**
 * @export
 */
export const AddNodeAndConnectionsToNetwork200ResponseInnerResultEnum = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
} as const;
export type AddNodeAndConnectionsToNetwork200ResponseInnerResultEnum = typeof AddNodeAndConnectionsToNetwork200ResponseInnerResultEnum[keyof typeof AddNodeAndConnectionsToNetwork200ResponseInnerResultEnum];


/**
 * Check if a given object implements the AddNodeAndConnectionsToNetwork200ResponseInner interface.
 */
export function instanceOfAddNodeAndConnectionsToNetwork200ResponseInner(value: object): value is AddNodeAndConnectionsToNetwork200ResponseInner {
    if (!('neighbour' in value) || value['neighbour'] === undefined) return false;
    if (!('result' in value) || value['result'] === undefined) return false;
    return true;
}

export function AddNodeAndConnectionsToNetwork200ResponseInnerFromJSON(json: any): AddNodeAndConnectionsToNetwork200ResponseInner {
    return AddNodeAndConnectionsToNetwork200ResponseInnerFromJSONTyped(json, false);
}

export function AddNodeAndConnectionsToNetwork200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNodeAndConnectionsToNetwork200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'neighbour': NeighbourFromJSON(json['neighbour']),
        'result': json['result'],
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function AddNodeAndConnectionsToNetwork200ResponseInnerToJSON(value?: AddNodeAndConnectionsToNetwork200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'neighbour': NeighbourToJSON(value['neighbour']),
        'result': value['result'],
        'reason': value['reason'],
    };
}

