import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import React from "react";
import { Modal } from "../../../components/Modal";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import { UncontrolledAccordion } from "../../../components/UncontrolledAccordion";
import { NodeErrorItem } from "../../../components/errorModal/NodeErrorItem";
import { Chip, ChipColor } from "../../../components/Chip";

type Props = {
  nodeId: string;
  connectionId: string;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
};

export const ConnectionErrorModal = ({
  nodeId,
  connectionId,
  setIsModalOpen,
  onClose,
}: Props) => {
  const api = useNodeApi();

  const nodeErrorsQuery = useQuery({
    queryKey: ["errors", nodeId, connectionId],
    queryFn: () =>
      api.getErrorsFromConnection({
        nodeId,
        connectionId,
        offset: 0,
        limit: 100,
      }),
  });

  const error = nodeErrorsQuery.error;
  const response = nodeErrorsQuery.data;

  return (
    <Modal
      size="large"
      toggleModal={setIsModalOpen}
      title={
        error == null && response != null
          ? `Errors (${response.results.length} of ${response.total})`
          : "error"
      }
      buttons={
        <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
          Cancel
        </Button>
      }
      isLoading={nodeErrorsQuery.isPending}
    >
      {error && (
        <ResponseErrorMessage error={error}>
          Error fetching errors
        </ResponseErrorMessage>
      )}
      {response?.results.map((error) => {
        return (
          <UncontrolledAccordion
            key={`${error.originNodeName} - ${error.id}`}
            label={
              <div className="space-x-1">
                <Chip color={ChipColor.BLUE}>{error.originNodeName}</Chip>{" "}
                <span>
                  {error.type} error {error.id} {error.rootCauseDetails ?? ""}
                </span>
              </div>
            }
          >
            <NodeErrorItem nodeError={error} />
          </UncontrolledAccordion>
        );
      })}
    </Modal>
  );
};
