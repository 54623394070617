import { Modal } from "../../../components/Modal";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { useMutation } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import { ResponseError } from "../../../../generated/syncroom-api/src";

type Props = {
  nodeId: string;
  connectionId: string;
  versionId: string;
  triggerId: string;
  onClose: () => void;
};

export const DeleteTriggerModal = ({
  nodeId,
  connectionId,
  versionId,
  triggerId,
  onClose,
}: Props) => {
  const nodesApi = useNodeApi();
  const deleteTriggerMutation = useMutation<void, ResponseError>({
    mutationFn: () =>
      nodesApi.deleteTrigger({
        nodeId,
        connectionId,
        versionId,
        triggerId,
      }),
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <Modal
      toggleModal={() => onClose()}
      title="Remove Trigger"
      buttons={
        <>
          <Button type={ButtonType.SECONDARY} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button onClick={() => deleteTriggerMutation.mutate()}>
            Remove trigger
          </Button>
        </>
      }
      isLoading={deleteTriggerMutation.isPending}
    >
      <p>
        You're about to remove this trigger. This action cannot be made undone.
      </p>
    </Modal>
  );
};
