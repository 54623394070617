/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserAssignedNetworksInner } from './UserAssignedNetworksInner';
import {
    UserAssignedNetworksInnerFromJSON,
    UserAssignedNetworksInnerFromJSONTyped,
    UserAssignedNetworksInnerToJSON,
} from './UserAssignedNetworksInner';

/**
 * User object
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {Array<UserAssignedNetworksInner>}
     * @memberof User
     */
    assignedNetworks: Array<UserAssignedNetworksInner>;
}


/**
 * @export
 */
export const UserRoleEnum = {
    SYSTEM_ADMIN: 'SYSTEM_ADMIN',
    NETWORK_ADMIN: 'NETWORK_ADMIN'
} as const;
export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('assignedNetworks' in value) || value['assignedNetworks'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'role': json['role'],
        'assignedNetworks': ((json['assignedNetworks'] as Array<any>).map(UserAssignedNetworksInnerFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'role': value['role'],
        'assignedNetworks': ((value['assignedNetworks'] as Array<any>).map(UserAssignedNetworksInnerToJSON)),
    };
}

