import { useNetworkInfoQuery } from "../network/useNetworkInfoQuery";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../components/Loader";

export const NetworkCrumb = () => {
  const { networkId } = useParams();
  if (!networkId)
    throw new Error("networkId should be a path param when using this crumb");

  const networkInfoQuery = useNetworkInfoQuery(networkId);

  if (networkInfoQuery.isPending) return <Loader />;
  if (networkInfoQuery.isError) return;

  return (
    <Link to={`/networks/${networkId}`}>{networkInfoQuery.data.name}</Link>
  );
};
