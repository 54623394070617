/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Validated script object
 * @export
 * @interface ValidatedScript
 */
export interface ValidatedScript {
    /**
     * Indicating if the script is valid or not
     * @type {boolean}
     * @memberof ValidatedScript
     */
    isValid: boolean;
    /**
     * The validation errors that can be passed to the Script Editor component.
     * @type {string}
     * @memberof ValidatedScript
     */
    errors?: string;
}

/**
 * Check if a given object implements the ValidatedScript interface.
 */
export function instanceOfValidatedScript(value: object): value is ValidatedScript {
    if (!('isValid' in value) || value['isValid'] === undefined) return false;
    return true;
}

export function ValidatedScriptFromJSON(json: any): ValidatedScript {
    return ValidatedScriptFromJSONTyped(json, false);
}

export function ValidatedScriptFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatedScript {
    if (json == null) {
        return json;
    }
    return {
        
        'isValid': json['isValid'],
        'errors': json['errors'] == null ? undefined : json['errors'],
    };
}

export function ValidatedScriptToJSON(value?: ValidatedScript | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isValid': value['isValid'],
        'errors': value['errors'],
    };
}

