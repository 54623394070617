import { useParams } from "react-router-dom";
import { NodeDetail } from "./NodeDetail";

export const NodeDetailPage = () => {
  const { nodeId } = useParams();

  if (!nodeId) return <h2>Error</h2>;

  return <NodeDetail nodeId={nodeId}></NodeDetail>;
};
