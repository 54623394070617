import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ReactNode, useState } from "react";

type Props = {
  label: ReactNode;
  children: ReactNode;
};

export const UncontrolledAccordion = ({ label, children }: Props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="mb-4 rounded-lg border">
      <span
        onClick={handleClick}
        className="bg-neutral-lesser_grey flex items-center justify-between p-4 font-medium leading-6 text-gray-900"
      >
        <label className="text-primary mr-4 overflow-hidden overflow-ellipsis whitespace-nowrap font-semibold leading-6">
          {label}
        </label>
        {isAccordionOpen ? (
          <FaChevronUp className="flex-shrink-0" />
        ) : (
          <FaChevronDown className="flex-shrink-0" />
        )}
      </span>
      {isAccordionOpen && children}
    </div>
  );
};
