/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Neighbour } from './Neighbour';
import {
    NeighbourFromJSON,
    NeighbourFromJSONTyped,
    NeighbourToJSON,
} from './Neighbour';

/**
 * 
 * @export
 * @interface FindNewNode200Response
 */
export interface FindNewNode200Response {
    /**
     * 
     * @type {string}
     * @memberof FindNewNode200Response
     */
    name: string;
    /**
     * 
     * @type {Array<Neighbour>}
     * @memberof FindNewNode200Response
     */
    neighbours: Array<Neighbour>;
}

/**
 * Check if a given object implements the FindNewNode200Response interface.
 */
export function instanceOfFindNewNode200Response(value: object): value is FindNewNode200Response {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('neighbours' in value) || value['neighbours'] === undefined) return false;
    return true;
}

export function FindNewNode200ResponseFromJSON(json: any): FindNewNode200Response {
    return FindNewNode200ResponseFromJSONTyped(json, false);
}

export function FindNewNode200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindNewNode200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'neighbours': ((json['neighbours'] as Array<any>).map(NeighbourFromJSON)),
    };
}

export function FindNewNode200ResponseToJSON(value?: FindNewNode200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'neighbours': ((value['neighbours'] as Array<any>).map(NeighbourToJSON)),
    };
}

