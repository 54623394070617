import { AddNodeAndConnectionsToNetwork200ResponseInner } from "../../../../generated/syncroom-api/src";
import { useFormikContext } from "formik";
import { FormValues } from "./SetNetworkForConnectedNodeModal";
import { NodesAddedStepBase } from "../../../components/NodesAddedStepBase";

type Props = {
  results: AddNodeAndConnectionsToNetwork200ResponseInner[];
  networkId: string;
  nodeName: string;
  nodeUrl: string;
};

export const NodesAddedStepForSetNetworkForConnectedNode = ({
  results,
  networkId,
  nodeName,
  nodeUrl,
}: Props) => {
  const formContext = useFormikContext<FormValues>();
  return (
    <NodesAddedStepBase
      results={results}
      networkId={networkId}
      nodeName={nodeName}
      nodeUrl={nodeUrl}
      tags={[]}
      centrallyManaged={formContext.values.centrallyManaged}
    />
  );
};
