import { NodeType } from "../../../generated/syncroom-api/src";
import { FaCircleQuestion } from "react-icons/fa6";
import DevOpsLogo from "../../images/Azure-DevOps.svg";
import GithubLogo from "../../images/github-mark-white.svg";
import JiraCloudLogo from "../../images/mark-gradient-blue-jira-software.svg";
import SalesForceLogo from "../../images/salesforce.svg";
import ServiceNowLogo from "../../images/servicenow_logo_icon.svg";
import ZendeskLogo from "../../images/zendesk-icon-svgrepo-com.svg";

type Props = {
  nodeType: NodeType;
};

const renderNodeIndicator = (
  data: string,
  className: string,
  description: string,
) => <img src={data} className={className + " max-w-none"} alt={description} />;

export const NodeTypeIndicator = ({ nodeType }: Props) => {
  const defaultSize = "size-11";
  switch (nodeType) {
    case NodeType.AZURE_DEVOPS:
      return renderNodeIndicator(DevOpsLogo, defaultSize, "DevOps Logo");
    case NodeType.GITHUB:
      return renderNodeIndicator(GithubLogo, defaultSize, "Github Logo");
    case NodeType.JIRA_CLOUD:
      return renderNodeIndicator(JiraCloudLogo, defaultSize, "Jira cloud Logo");
    case NodeType.SALESFORCE:
      return renderNodeIndicator(
        SalesForceLogo,
        defaultSize,
        "SalesForce Logo",
      );
    case NodeType.SERVICE_NOW:
      return renderNodeIndicator(ServiceNowLogo, "size-9", "ServiceNow Logo");
    case NodeType.ZENDESK:
      return renderNodeIndicator(ZendeskLogo, defaultSize, "Zendesk Logo");
    default:
      return <FaCircleQuestion className={defaultSize} />;
  }
};
