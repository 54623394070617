import React, { ReactNode } from "react";
import { FaXmark } from "react-icons/fa6";
import { Button, ButtonType } from "./buttons/Button";
import { usePreventScrollingOutsideModal } from "../shared/usePreventScrollingOutsideModal";

type Props = {
  title: string;
  subHeader?: string;
  onClose: () => void;
  children: ReactNode;
  leftButtons?: ReactNode;
  rightButtons?: ReactNode;
};
export const FullScreenModal = ({
  children,
  title,
  subHeader,
  leftButtons,
  rightButtons,
  onClose,
}: Props) => {
  usePreventScrollingOutsideModal();

  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-[#95919E] bg-opacity-90">
        <div className="h-dvh pt-20">
          <div
            role="dialog"
            aria-label={title}
            className="flex h-full flex-col rounded-t-3xl bg-white"
          >
            <div className="flex h-20 shrink-0 items-center justify-between border-b border-zinc-200 px-8">
              <div className="flex items-center gap-3">
                {leftButtons}
                <div>
                  <h5 className="heading-5">{title}</h5>
                  {subHeader && (
                    <span className="text-zinc-400">{subHeader}</span>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-end gap-3">
                {rightButtons}
                <Button
                  type={ButtonType.SECONDARY}
                  onClick={() => onClose()}
                  label="Close modal"
                >
                  <FaXmark />
                </Button>
              </div>
            </div>
            <div className="h-full overflow-scroll">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
