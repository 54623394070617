import React, { InputHTMLAttributes, ReactNode } from "react";
import { Field } from "formik";
import { FormFieldError } from "./FormFieldError";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  showError?: boolean;
  children?: ReactNode;
};
export const FormCheckboxInput = ({
  label,
  name,
  disabled,
  showError = true,
  children,
  ...rest
}: Props) => {
  return (
    <div>
      <label className="flex cursor-pointer items-center gap-3">
        <Field
          {...rest}
          type="checkbox"
          name={name}
          className="checked:bg-primary-bright_purple checked:hover:bg-primary-bright_purple focus:checked:bg-primary-bright_purple h-5 w-5 cursor-pointer rounded outline-0 focus:bg-transparent focus:ring-0 disabled:bg-gray-300"
          disabled={disabled}
        />
        {label && (
          <span className={disabled ? "text-gray-400" : ""}>{label}</span>
        )}
        {children}
      </label>
      {showError && <FormFieldError name={name} />}
    </div>
  );
};
