/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Definition of an item with its name and signature
 * @export
 * @interface IdeSuggestionItem
 */
export interface IdeSuggestionItem {
    /**
     * Name of the item
     * @type {string}
     * @memberof IdeSuggestionItem
     */
    name: string;
    /**
     * Signature of the item, indicating its type or structure
     * @type {string}
     * @memberof IdeSuggestionItem
     */
    signature?: string | null;
}

/**
 * Check if a given object implements the IdeSuggestionItem interface.
 */
export function instanceOfIdeSuggestionItem(value: object): value is IdeSuggestionItem {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function IdeSuggestionItemFromJSON(json: any): IdeSuggestionItem {
    return IdeSuggestionItemFromJSONTyped(json, false);
}

export function IdeSuggestionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdeSuggestionItem {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'signature': json['signature'] == null ? undefined : json['signature'],
    };
}

export function IdeSuggestionItemToJSON(value?: IdeSuggestionItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'signature': value['signature'],
    };
}

