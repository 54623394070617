import { NodeTypeIndicator } from "../node/NodeTypeIndicator";
import React from "react";
import { NodeType } from "../../../generated/syncroom-api/src";

type Props = {
  type: NodeType;
  name: string;
};

export const NodeIconWithName = ({ type, name }: Props) => {
  return (
    <td className="flex items-center gap-2 truncate">
      <NodeTypeIndicator nodeType={type} />
      {name}
    </td>
  );
};
