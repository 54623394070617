import React, { ReactNode } from "react";
import { FaCircleExclamation } from "react-icons/fa6";

type Props = {
  children: ReactNode;
};

export const ErrorBanner = ({ children }: Props) => {
  return (
    <div className="border-l-danger flex gap-4 rounded-lg border border-l-8 border-red-300 bg-red-50 p-4">
      <div className="text-danger flex items-center text-xl">
        <FaCircleExclamation />
      </div>
      <div className="leading-normal">{children}</div>
    </div>
  );
};
