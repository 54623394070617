import { ReactNode } from "react";
import { useAuth } from "react-oidc-context";
import { Roles } from "../shared/Roles";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode;
  role: Roles;
  redirectToAccessDenied?: boolean;
};

export const GuardedComponent = ({
  children,
  role,
  redirectToAccessDenied = true,
}: Props) => {
  const auth = useAuth();
  const realmAccess = auth.user?.profile["realm_access"] as { roles: string[] };

  if (realmAccess.roles.includes(role)) return children;
  else if (redirectToAccessDenied) return <Navigate to="/access_denied" />;
  else return <></>;
};
