import { Issue } from "../../../../../generated/syncroom-api/src";

type Props = {
  issues: Issue[];
  selectedIssue: Issue;
  setSelectedIssue: (issue: Issue) => void;
};

export const TicketSelection = ({
  issues,
  selectedIssue,
  setSelectedIssue,
}: Props) => {
  return (
    <div className="border-neutral-grey flex w-full overflow-x-scroll border-b">
      {issues.map((issue) => (
        <button
          key={issue.issueUrn}
          className={`hover:bg-neutral-lesser_grey flex h-16 min-w-64 items-center justify-center p-2.5 ${issue.issueUrn === selectedIssue.issueUrn ? "border-b-primary-light bg-neutral-lesser_grey border-b-4" : ""}`}
          onClick={() => setSelectedIssue(issue)}
        >
          <p className="text-primary font-bold">{issue.issueUrn}</p>
        </button>
      ))}
    </div>
  );
};
