import React, { ReactNode } from "react";
import { FaXmark } from "react-icons/fa6";
import { Loader } from "./Loader";
import { usePreventScrollingOutsideModal } from "../shared/usePreventScrollingOutsideModal";

type Props = {
  title: string;
  toggleModal: (value: boolean) => void;
  children: ReactNode;
  size?: "normal" | "large";
  isLoading?: boolean;
  buttons?: ReactNode;
};
export const Modal = ({
  title,
  toggleModal,
  size = "normal",
  children,
  isLoading = false,
  buttons,
}: Props) => {
  usePreventScrollingOutsideModal();

  const sizeStyle = size === "normal" ? "max-w-3xl" : "max-w-7xl";

  const clickHandler = () => {
    toggleModal(false);
  };

  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-[#95919E] bg-opacity-90"></div>
      <div className={`fixed inset-0 z-10 mx-auto ${sizeStyle}`}>
        <div className="flex min-h-full w-full items-center p-4 text-center">
          <div
            role="dialog"
            aria-label={title}
            className="relative w-full transform overflow-x-hidden rounded-lg bg-white text-left shadow-xl"
          >
            <div className="text-primary h-18 bg-neutral-lesser_grey flex items-center justify-between px-6">
              <h5 className="heading-5">{title}</h5>

              <button
                type="button"
                className="p-1 text-xl"
                aria-label="Close modal"
                onClick={clickHandler}
              >
                <FaXmark />
              </button>
            </div>

            <div className="max-h-[80vh] overflow-y-auto bg-white p-6">
              {isLoading && (
                <div className="fixed inset-0 z-10 flex justify-center bg-gray-500 bg-opacity-30">
                  <Loader />
                </div>
              )}
              <div className="text-left">
                <div className="mt-2 whitespace-pre-wrap">{children}</div>
              </div>
            </div>

            {buttons && (
              <div className="h-18 bg-neutral-lesser_grey flex items-center justify-end gap-3 rounded-b-lg px-6">
                {buttons}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
