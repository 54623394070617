import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../shared/useNodeApi";

export const useConnectionDetailQuery = (
  nodeId: string,
  connectionId: string,
) => {
  const api = useNodeApi();

  return useQuery({
    queryKey: ["nodes", nodeId, "connections", connectionId],
    queryFn: () =>
      api.getConnectionDetail({
        nodeId,
        connectionId,
      }),
  });
};
