import {
  ConnectionDetail,
  type Scripts,
} from "../../../../generated/syncroom-api/src";
import { ScriptEditor } from "./ScriptEditor";
import { ScriptEditorHeader } from "./ScriptEditorHeader";

type Props = {
  connection: ConnectionDetail;
  scripts: Scripts;
  updateScripts: (scripts: Scripts) => void;
  incomingScriptError?: string;
  outgoingScriptError?: string;
};

export const ScriptEditorSideBySide = ({
  connection,
  scripts,
  updateScripts,
  incomingScriptError,
  outgoingScriptError,
}: Props) => {
  return (
    <div className="flex h-full items-stretch">
      <div
        className="flex w-1/2 flex-col justify-stretch border-r border-zinc-200"
        aria-label="Edit Outgoing script"
      >
        <ScriptEditorHeader
          header="Outgoing script"
          subHeader={"From " + connection.sourceNode.customName}
        />
        <ScriptEditor
          nodeId={connection.sourceNode.id}
          script={scripts.outgoing}
          onChange={(updatedScript) =>
            updateScripts({
              outgoing: updatedScript,
              incoming: scripts.incoming,
            })
          }
          error={outgoingScriptError}
        />
      </div>
      <div
        className="flex w-1/2 flex-col justify-stretch"
        aria-label="Edit Incoming script"
      >
        <ScriptEditorHeader
          header="Incoming script"
          subHeader={"Into " + connection.targetNode.customName}
        />
        <ScriptEditor
          nodeId={connection.targetNode.id}
          script={scripts.incoming}
          onChange={(updatedScript) =>
            updateScripts({
              outgoing: scripts.outgoing,
              incoming: updatedScript,
            })
          }
          error={incomingScriptError}
        />
      </div>
    </div>
  );
};
