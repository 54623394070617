import { ReactNode } from "react";

type Props = {
  header: string;
  subHeader?: string;
  buttons?: ReactNode;
  children: ReactNode;
  padding?: boolean;
};

export const Card = ({
  header,
  subHeader,
  buttons,
  children,
  padding = true,
}: Props) => {
  return (
    <div aria-label={header} className="flex flex-col rounded-lg shadow">
      <div className="h-18 flex items-center justify-between gap-2.5 rounded-t-lg border border-zinc-200 bg-white px-6">
        <div className="flex flex-col">
          <h2 className="heading-2">{header}</h2>
          {subHeader && (
            <span className="text-sm text-zinc-400">{subHeader}</span>
          )}
        </div>
        <div className="flex gap-4">{buttons}</div>
      </div>
      <div
        className={`grow rounded-b-lg border border-t-0 border-zinc-200 bg-white ${padding ? "p-6" : ""} `}
      >
        {children}
      </div>
    </div>
  );
};
