import { ReplicaTitle, TitleType } from "./ReplicaTitle";
import {
  Node,
  StartTestRunIncoming200ResponseInner,
  StartTestRunOutgoing200ResponseInner,
} from "../../../../../generated/syncroom-api/src";

type Props = {
  node: Node;
  issueUrn?: string;
  type: TitleType;
  result:
    | StartTestRunOutgoing200ResponseInner
    | StartTestRunIncoming200ResponseInner
    | undefined;
};

export const Replica = ({ node, issueUrn, type, result }: Props) => {
  if (result === undefined) {
    return (
      <ReplicaTitle
        node={node}
        issueUrn={undefined}
        type={TitleType.NO_RESPONSE}
        errorPresent={false}
      />
    );
  }
  const replicaErrorPresent = result.errors !== undefined;
  return (
    <>
      <ReplicaTitle
        node={node}
        issueUrn={issueUrn}
        type={type}
        errorPresent={replicaErrorPresent}
      />
      <pre className="bg-neutral-lesser_grey text-wrap p-4">
        {JSON.stringify(
          replicaErrorPresent ? result.errors : result.replica,
          null,
          2,
        )}
      </pre>
    </>
  );
};
