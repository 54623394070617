/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';
import type { ConnectedNodeConnection } from './ConnectedNodeConnection';
import {
    ConnectedNodeConnectionFromJSON,
    ConnectedNodeConnectionFromJSONTyped,
    ConnectedNodeConnectionToJSON,
} from './ConnectedNodeConnection';
import type { NodeStatus } from './NodeStatus';
import {
    NodeStatusFromJSON,
    NodeStatusFromJSONTyped,
    NodeStatusToJSON,
} from './NodeStatus';

/**
 * The response object for the connected nodes table.
 * @export
 * @interface ConnectedNode
 */
export interface ConnectedNode {
    /**
     * The nodeId of a known node. Is only set if the node is known
     * @type {string}
     * @memberof ConnectedNode
     */
    id?: string;
    /**
     * 
     * @type {NodeType}
     * @memberof ConnectedNode
     */
    type: NodeType;
    /**
     * 
     * @type {string}
     * @memberof ConnectedNode
     */
    customName: string;
    /**
     * 
     * @type {NodeStatus}
     * @memberof ConnectedNode
     */
    status?: NodeStatus;
    /**
     * 
     * @type {string}
     * @memberof ConnectedNode
     */
    baseUrl: string;
    /**
     * 
     * @type {Array<ConnectedNodeConnection>}
     * @memberof ConnectedNode
     */
    connections: Array<ConnectedNodeConnection>;
}



/**
 * Check if a given object implements the ConnectedNode interface.
 */
export function instanceOfConnectedNode(value: object): value is ConnectedNode {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('customName' in value) || value['customName'] === undefined) return false;
    if (!('baseUrl' in value) || value['baseUrl'] === undefined) return false;
    if (!('connections' in value) || value['connections'] === undefined) return false;
    return true;
}

export function ConnectedNodeFromJSON(json: any): ConnectedNode {
    return ConnectedNodeFromJSONTyped(json, false);
}

export function ConnectedNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedNode {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'type': NodeTypeFromJSON(json['type']),
        'customName': json['customName'],
        'status': json['status'] == null ? undefined : NodeStatusFromJSON(json['status']),
        'baseUrl': json['baseUrl'],
        'connections': ((json['connections'] as Array<any>).map(ConnectedNodeConnectionFromJSON)),
    };
}

export function ConnectedNodeToJSON(value?: ConnectedNode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': NodeTypeToJSON(value['type']),
        'customName': value['customName'],
        'status': NodeStatusToJSON(value['status']),
        'baseUrl': value['baseUrl'],
        'connections': ((value['connections'] as Array<any>).map(ConnectedNodeConnectionToJSON)),
    };
}

