import { Form, Formik } from "formik";
import { Modal } from "../../components/Modal";
import { Button, ButtonType } from "../../components/buttons/Button";
import { FormTextInput } from "../../components/form/FormTextInput";
import React from "react";
import { NetworkRequestBody } from "../../../generated/syncroom-api/src";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNetworksApi } from "../../shared/useNetworksApi";
import { Network } from "../../../generated/syncroom-api/src/";
import { FormTextareaInput } from "../../components/form/FormTextareaInput";

type Props = {
  networkInfo: Network;
  toggleModal: () => void;
};

export const EditNetworkDetailsModal = ({
  networkInfo,
  toggleModal,
}: Props) => {
  const api = useNetworksApi();
  const queryClient = useQueryClient();

  const updateNetworkValidationScheme = yup.object({
    name: yup
      .string()
      .required("Network name is a required field")
      .trim()
      .max(255, "Network name can not be longer then 255 characters"),
    purpose: yup.string().required("Purpose is a required field").trim(),
  });

  const updateNetworkMutation = useMutation({
    mutationFn: (networkRequestBody: NetworkRequestBody) =>
      api.updateNetwork({
        networkId: networkInfo.id,
        networkRequestBody,
      }),
    onSuccess: async () => {
      toggleModal();
      await queryClient.invalidateQueries({
        queryKey: ["networks", networkInfo.id],
      });
    },
  });

  return (
    <Formik<NetworkRequestBody>
      enableReinitialize={true}
      initialValues={{
        name: networkInfo.name,
        purpose: networkInfo.purpose,
      }}
      validationSchema={updateNetworkValidationScheme}
      onSubmit={async (values) =>
        updateNetworkMutation.mutate(
          await updateNetworkValidationScheme.validate(values),
        )
      }
    >
      <Form noValidate className="h-full">
        <Modal
          title="Edit network"
          toggleModal={() => toggleModal()}
          buttons={
            <>
              <Button onClick={() => toggleModal()} type={ButtonType.SECONDARY}>
                Cancel
              </Button>
              <Button buttonType="submit">Submit</Button>
            </>
          }
        >
          <div className="grid grid-cols-1 gap-3">
            <FormTextInput label="Name" name="name" />
            <FormTextareaInput label="Purpose" name="purpose" />
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
