/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ValidatedScript } from './ValidatedScript';
import {
    ValidatedScriptFromJSON,
    ValidatedScriptFromJSONTyped,
    ValidatedScriptToJSON,
} from './ValidatedScript';

/**
 * 
 * @export
 * @interface UpdateConnectionScripts200Response
 */
export interface UpdateConnectionScripts200Response {
    /**
     * 
     * @type {ValidatedScript}
     * @memberof UpdateConnectionScripts200Response
     */
    incoming: ValidatedScript;
    /**
     * 
     * @type {ValidatedScript}
     * @memberof UpdateConnectionScripts200Response
     */
    outgoing: ValidatedScript;
}

/**
 * Check if a given object implements the UpdateConnectionScripts200Response interface.
 */
export function instanceOfUpdateConnectionScripts200Response(value: object): value is UpdateConnectionScripts200Response {
    if (!('incoming' in value) || value['incoming'] === undefined) return false;
    if (!('outgoing' in value) || value['outgoing'] === undefined) return false;
    return true;
}

export function UpdateConnectionScripts200ResponseFromJSON(json: any): UpdateConnectionScripts200Response {
    return UpdateConnectionScripts200ResponseFromJSONTyped(json, false);
}

export function UpdateConnectionScripts200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateConnectionScripts200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'incoming': ValidatedScriptFromJSON(json['incoming']),
        'outgoing': ValidatedScriptFromJSON(json['outgoing']),
    };
}

export function UpdateConnectionScripts200ResponseToJSON(value?: UpdateConnectionScripts200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'incoming': ValidatedScriptToJSON(value['incoming']),
        'outgoing': ValidatedScriptToJSON(value['outgoing']),
    };
}

