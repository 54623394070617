import { Loader } from "./Loader";

export const LoadingPage = () => (
  <div
    className="flex min-h-screen flex-col items-center justify-center text-center"
    data-testid="loading-page"
  >
    <main>
      <Loader />
    </main>
  </div>
);
