/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Neighbour node properties
 * @export
 * @interface Neighbour
 */
export interface Neighbour {
    /**
     * 
     * @type {string}
     * @memberof Neighbour
     */
    connectionName: string;
    /**
     * 
     * @type {string}
     * @memberof Neighbour
     */
    url: string;
}

/**
 * Check if a given object implements the Neighbour interface.
 */
export function instanceOfNeighbour(value: object): value is Neighbour {
    if (!('connectionName' in value) || value['connectionName'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function NeighbourFromJSON(json: any): Neighbour {
    return NeighbourFromJSONTyped(json, false);
}

export function NeighbourFromJSONTyped(json: any, ignoreDiscriminator: boolean): Neighbour {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionName': json['connectionName'],
        'url': json['url'],
    };
}

export function NeighbourToJSON(value?: Neighbour | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'connectionName': value['connectionName'],
        'url': value['url'],
    };
}

