type Props = {
  header: string;
  subHeader: string;
};

export const ScriptEditorHeader = ({ header, subHeader }: Props) => {
  return (
    <div className="h-18 flex flex-shrink-0 items-center border-b border-zinc-200 px-8">
      <div>
        <div className="font-header text-primary font-semibold">{header}</div>
        <div className="text-sm text-zinc-400">{subHeader}</div>
      </div>
    </div>
  );
};
