import { useMatches } from "react-router-dom";
import { Fragment, ReactNode } from "react";

type Handle = {
  crumb: () => ReactNode;
};

export const Breadcrumbs = () => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean((match.handle as Handle)?.crumb))
    .map((match) => (match.handle as Handle).crumb());

  return (
    crumbs.length > 1 && (
      <ul className="inline-flex h-11 justify-start gap-2">
        {crumbs.map((crumb, index) => {
          return (
            <Fragment key={`crumb-${index}`}>
              <li className="last:override-cursor flex items-center justify-center gap-2.5 p-1 [&:not(:last-child)]:font-semibold [&:not(:last-child)]:underline">
                {crumb}
              </li>
              {index !== crumbs.length - 1 && (
                <li className="flex items-center justify-center text-gray-400">
                  /
                </li>
              )}
            </Fragment>
          );
        })}
      </ul>
    )
  );
};
