import { FaCaretDown } from "react-icons/fa";
import { components, DropdownIndicatorProps } from "react-select";

type Props = {
  props: DropdownIndicatorProps<any>;
};

export const SelectDropdownIndicator = ({ props }: Props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="text-neutral-greyer_grey">
        <FaCaretDown />
      </div>
    </components.DropdownIndicator>
  );
};
