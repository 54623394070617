import {
  AddNodeAndConnectionsToNetwork200ResponseInner,
  Neighbour,
  ResponseError,
} from "../../generated/syncroom-api/src";
import {
  FaCircleCheck,
  FaCircleExclamation,
  FaRotateRight,
} from "react-icons/fa6";
import { Button, ButtonSize, ButtonType } from "./buttons/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNetworksApi } from "../shared/useNetworksApi";
import React, { useState } from "react";

type Props = {
  results: AddNodeAndConnectionsToNetwork200ResponseInner[];
  networkId: string;
  nodeName: string;
  nodeUrl: string;
  tags: string[];
  centrallyManaged: boolean;
};

export const NodesAddedStepBase = ({
  results,
  networkId,
  nodeName,
  nodeUrl,
  tags,
  centrallyManaged,
}: Props) => {
  const queryClient = useQueryClient();
  const api = useNetworksApi();
  const [resultState, setResultState] = useState(results);

  const retryAddNodeAndConnectionsToNetwork = useMutation<
    AddNodeAndConnectionsToNetwork200ResponseInner[],
    ResponseError,
    Neighbour
  >({
    mutationFn: (retryNeighbour: Neighbour) =>
      api.addNodeAndConnectionsToNetwork({
        networkId,
        addNodeAndConnectionsToNetworkRequest: {
          name: nodeName,
          url: nodeUrl,
          tags: tags,
          neighbours: [retryNeighbour],
          centrallyManaged: centrallyManaged,
        },
      }),
    onSuccess: async (result) => {
      await queryClient.invalidateQueries({
        queryKey: ["networks", networkId, "nodes"],
      });

      const index = resultState.findIndex(
        (item) =>
          item.neighbour.connectionName === result[0].neighbour.connectionName,
      );

      const connections = [...resultState];
      connections[index] = result[0];
      setResultState(connections);
    },
  });

  return (
    <div className="flex flex-col gap-6">
      {resultState.map(
        (
          nodeAddedResponse: AddNodeAndConnectionsToNetwork200ResponseInner,
          index,
        ) => {
          return (
            <div
              className="inline-flex items-center justify-start gap-5"
              key={nodeAddedResponse.neighbour.connectionName}
            >
              {nodeAddedResponse.result === "SUCCESS" && (
                <div className="inline-flex h-12 w-12 items-center justify-center gap-2.5 rounded-2xl bg-emerald-50">
                  <FaCircleCheck className="flex h-6 w-6 items-center justify-center p-0.5 text-emerald-600" />
                </div>
              )}
              {nodeAddedResponse.result === "FAILED" && (
                <div className="inline-flex h-12 w-12 items-center justify-center gap-2.5 rounded-2xl bg-red-400 bg-opacity-20">
                  <FaCircleExclamation className="flex h-6 w-6 items-center justify-center p-0.5 text-red-700" />
                </div>
              )}
              <div className="flex shrink grow basis-0 flex-row items-center gap-7">
                <div>
                  <p className="font-bold">
                    Connection {index + 1}/{results.length}:{" "}
                    {nodeAddedResponse.neighbour.connectionName}
                  </p>
                  <p>
                    {nodeAddedResponse.result === "SUCCESS"
                      ? "This node was successfully added to your network."
                      : nodeAddedResponse.reason}
                  </p>
                </div>
                {nodeAddedResponse.result === "FAILED" && (
                  <Button
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      retryAddNodeAndConnectionsToNetwork.mutate(
                        nodeAddedResponse.neighbour,
                      );
                    }}
                    iconLeft={<FaRotateRight />}
                    disabled={retryAddNodeAndConnectionsToNetwork.isPending}
                  >
                    Try again
                  </Button>
                )}
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
