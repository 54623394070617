import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";
import { useMemo } from "react";
import { useNetworksApi } from "../shared/useNetworksApi";
import { FormFieldError } from "../components/form/FormFieldError";
import { UserAssignedNetworksInner } from "../../generated/syncroom-api/src";
import {
  SelectOption,
  SelectWrapper,
} from "../components/select/SelectWrapper";
import { FormLabel } from "../components/form/FormLabel";

type Props = {
  name: string;
  disabled: boolean;
};

export const NetworksSelect = ({ name, disabled }: Props) => {
  const [field, _, helper] = useField<UserAssignedNetworksInner[]>(name);
  const api = useNetworksApi();
  const getNetworksQuery = useQuery({
    queryKey: ["networks"],
    queryFn: () => api.getNetworks(),
  });

  const networkOptions: SelectOption<UserAssignedNetworksInner>[] =
    useMemo(() => {
      return (getNetworksQuery.data || []).map((network) => ({
        value: { networkId: network.id, networkName: network.name },
        label: network.name,
      }));
    }, [getNetworksQuery.data]);

  const currentSelected: SelectOption<UserAssignedNetworksInner>[] =
    useMemo(() => {
      return field.value.map((userAssignedNetworkInner) => ({
        value: userAssignedNetworkInner,
        label: userAssignedNetworkInner.networkName,
      }));
    }, [field.value]);

  return (
    <div>
      <label>
        <FormLabel>Networks</FormLabel>
        <SelectWrapper
          {...field}
          isOptionSelected={(option, selectValue) =>
            selectValue
              .map((value) => value.value.networkId)
              .includes(option.value.networkId)
          }
          options={networkOptions}
          isMulti={true}
          onChange={(newvalue) => helper.setValue(newvalue.map((i) => i.value))}
          value={currentSelected}
          isDisabled={disabled}
        />
      </label>
      <FormFieldError name={field.name} />
    </div>
  );
};
