import { Button, ButtonType } from "../../../components/buttons/Button";
import { useQuery } from "@tanstack/react-query";
import { useNetworksApi } from "../../../shared/useNetworksApi";
import { ReactNode, useEffect, useState } from "react";
import { FormValues } from "./AddNodeModal";
import { FormikContextType, useFormikContext } from "formik";
import { Loader } from "../../../components/Loader";
import { FormCheckboxInput } from "../../../components/form/FormCheckboxInput";
import { handleResponseError } from "../../../util/errors";
import { FormFieldError } from "../../../components/form/FormFieldError";
import { Neighbour } from "../../../../generated/syncroom-api/src";

type Props = {
  networkId: string;
  setError: (error: string | undefined) => void;
};

type TextWithButtonWrapperProps = {
  formikContext: FormikContextType<FormValues>;
  setUrl: (url: string) => void;
  children: ReactNode;
};

const TextWithButtonWrapper = ({
  formikContext,
  setUrl,
  children,
}: TextWithButtonWrapperProps) => {
  return (
    <div className="flex justify-between gap-2">
      <div>{children}</div>
      <div className="my-auto min-w-40">
        <Button
          type={ButtonType.PRIMARY}
          onClick={async () => {
            const errors = await formikContext.validateForm();
            if (errors.url === undefined) {
              const trimmedUrl = formikContext.values.url.trim();
              await formikContext.setFieldValue("url", trimmedUrl);
              setUrl(trimmedUrl);
              await formikContext.setFieldValue("neighboursFetched", true);
            } else {
              await formikContext.setFieldTouched("url");
            }
          }}
        >
          Find neighbours
        </Button>
      </div>
    </div>
  );
};

export const SelectNeighbours = ({ networkId, setError }: Props) => {
  const [url, setUrl] = useState<string>();
  const api = useNetworksApi();

  const formikContext = useFormikContext<FormValues>();

  const findNewNodeResponse = useQuery({
    queryKey: ["findNewNode", url!],
    queryFn: () =>
      api.findNewNode({
        networkId,
        url: url!,
      }),
    enabled: !!url,
    retry: false,
  });

  useEffect(() => {
    if (findNewNodeResponse.error) {
      handleResponseError(findNewNodeResponse.error, (errorMessage) =>
        setError(errorMessage),
      );
    } else {
      setError(undefined);
    }
  }, [findNewNodeResponse.error, setError]);

  const isGettingNeighbours =
    findNewNodeResponse.isPending &&
    findNewNodeResponse.isFetching &&
    !findNewNodeResponse.isError;

  const connectingNodes = findNewNodeResponse.data;

  const title = <p className="font-bold">Neighbours & connections</p>;

  const onChangeNeighbour = (neighbour: Neighbour, selected: boolean) => {
    if (selected) {
      formikContext.setFieldValue("neighbours", [
        ...formikContext.values.neighbours,
        neighbour,
      ]);
    } else {
      formikContext.setFieldValue(
        "neighbours",
        formikContext.values.neighbours.filter(
          (n) => n.connectionName !== neighbour.connectionName,
        ),
      );
    }
  };

  return (
    <div className="my-4">
      <div className="rounded-lg bg-gray-100 px-5 py-3">
        {connectingNodes === undefined ? (
          <TextWithButtonWrapper formikContext={formikContext} setUrl={setUrl}>
            <div className="flex gap-4">
              <div>{isGettingNeighbours && <Loader />}</div>
              <div className="space-y-2">
                {title}
                <p className="text-gray-400">
                  {isGettingNeighbours
                    ? "Searching for neighbours and connections..."
                    : "Click 'Find neighbours' to view all connections linked to the node you're adding, and choose the ones you want to manage in SyncRoom."}
                </p>
              </div>
            </div>
          </TextWithButtonWrapper>
        ) : (
          <div>
            {connectingNodes.neighbours.length > 0 ? (
              <>
                {title}
                {connectingNodes.neighbours.map((neighbour) => {
                  return (
                    <div key={neighbour.connectionName} className="py-2">
                      <FormCheckboxInput
                        name="neighbours"
                        label={neighbour.connectionName}
                        value={neighbour.connectionName}
                        checked={formikContext.values.neighbours
                          .map((n) => n.connectionName)
                          .includes(neighbour.connectionName)}
                        onChange={(event) =>
                          onChangeNeighbour(neighbour, event.target.checked)
                        }
                        showError={false}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <TextWithButtonWrapper
                formikContext={formikContext}
                setUrl={setUrl}
              >
                <div className="space-y-2">
                  <p className="font-bold">
                    No neighbours or connections to be added.
                  </p>
                  <p>
                    This might be because all connections are already managed or
                    there are no connections available on the node.
                  </p>
                </div>
              </TextWithButtonWrapper>
            )}
          </div>
        )}
      </div>
      <div className="justify-self-start pt-2">
        <FormFieldError name="neighbours" />
      </div>
    </div>
  );
};
