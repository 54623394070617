import { Card } from "../../../components/layout/Card";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../components/buttons/Button";
import { FaPencil } from "react-icons/fa6";
import { Script } from "./Script";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import {
  ConnectionDetail,
  ConnectionVersion,
} from "../../../../generated/syncroom-api/src";
import { Loader } from "../../../components/Loader";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import { TestRunModal } from "./test-run/TestRunModal";

type Props = {
  nodeId: string;
  connection: ConnectionDetail;
  selectedVersion?: ConnectionVersion;
  isActive: boolean;
};

export const ConnectionScripts = ({
  nodeId,
  connection,
  selectedVersion,
  isActive,
}: Props) => {
  const api = useNodeApi();
  const [showTestRunModal, setShowTestRunModal] = useState(false);

  const queryKey = [
    "nodes",
    nodeId,
    "connections",
    connection.id,
    "versions",
    selectedVersion?.id,
    "scripts",
  ];

  const scriptsQuery = useQuery({
    queryKey,
    queryFn: () =>
      api.getConnectionScripts({
        nodeId,
        connectionId: connection.id,
        versionId: selectedVersion?.id,
      }),
  });

  if (scriptsQuery.isPending) return <Loader />;

  if (scriptsQuery.isError)
    return (
      <ResponseErrorMessage error={scriptsQuery.error}>
        Error fetching scripts
      </ResponseErrorMessage>
    );

  return (
    <div>
      {showTestRunModal && (
        <TestRunModal
          connection={connection}
          selectedVersion={selectedVersion!}
          scripts={scriptsQuery.data}
          onClose={() => setShowTestRunModal(false)}
          queryKey={queryKey}
          nodeId={nodeId}
        />
      )}
      <div className="mb-4 flex items-center justify-between">
        <h3 className="heading-3">Scripts</h3>
        <Button
          onClick={() => setShowTestRunModal(true)}
          size={ButtonSize.MEDIUM}
          type={ButtonType.SECONDARY}
          iconLeft={<FaPencil />}
          disabled={isActive}
          label="Edit Script"
        >
          Edit
        </Button>
      </div>

      <div className="grid h-full grid-cols-2 gap-10">
        <Card
          header="Outgoing script"
          subHeader={`From ${connection.sourceNode.customName}`}
          padding={false}
        >
          <Script script={scriptsQuery.data.outgoing} />
        </Card>
        <Card
          header="Incoming script"
          subHeader={`Into ${connection.targetNode.customName}`}
          padding={false}
        >
          <Script script={scriptsQuery.data.incoming} />
        </Card>
      </div>
    </div>
  );
};
