/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeErrorWithOriginNode } from './NodeErrorWithOriginNode';
import {
    NodeErrorWithOriginNodeFromJSON,
    NodeErrorWithOriginNodeFromJSONTyped,
    NodeErrorWithOriginNodeToJSON,
} from './NodeErrorWithOriginNode';

/**
 * 
 * @export
 * @interface GetErrorsFromConnection200Response
 */
export interface GetErrorsFromConnection200Response {
    /**
     * 
     * @type {number}
     * @memberof GetErrorsFromConnection200Response
     */
    total: number;
    /**
     * 
     * @type {Array<NodeErrorWithOriginNode>}
     * @memberof GetErrorsFromConnection200Response
     */
    results: Array<NodeErrorWithOriginNode>;
}

/**
 * Check if a given object implements the GetErrorsFromConnection200Response interface.
 */
export function instanceOfGetErrorsFromConnection200Response(value: object): value is GetErrorsFromConnection200Response {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function GetErrorsFromConnection200ResponseFromJSON(json: any): GetErrorsFromConnection200Response {
    return GetErrorsFromConnection200ResponseFromJSONTyped(json, false);
}

export function GetErrorsFromConnection200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetErrorsFromConnection200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'results': ((json['results'] as Array<any>).map(NodeErrorWithOriginNodeFromJSON)),
    };
}

export function GetErrorsFromConnection200ResponseToJSON(value?: GetErrorsFromConnection200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
        'results': ((value['results'] as Array<any>).map(NodeErrorWithOriginNodeToJSON)),
    };
}

