import { Sidebar } from "./components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import React from "react";

export const MainLayout = () => (
  <div className="flex h-screen max-h-screen min-h-screen">
    <Sidebar />
    <div className="flex h-full w-full flex-col py-10 pr-10">
      <Outlet />
    </div>
  </div>
);
