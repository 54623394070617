export const NodesIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="current"
    stroke="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 1">
      <path
        id="Vector"
        d="M0.333313 2C0.333313 1.07953 1.0795 0.333336 1.99998 0.333336C2.92045 0.333336 3.66665 1.07953 3.66665 2C3.66665 2.92048 2.92045 3.66667 1.99998 3.66667C1.0795 3.66667 0.333313 2.92048 0.333313 2Z"
      />
      <path
        id="Vector_2"
        d="M5.33331 2C5.33331 1.07953 6.07951 0.333336 6.99998 0.333336C7.92045 0.333336 8.66665 1.07953 8.66665 2C8.66665 2.92048 7.92045 3.66667 6.99998 3.66667C6.07951 3.66667 5.33331 2.92048 5.33331 2Z"
      />
      <path
        id="Vector_3"
        d="M10.3333 2C10.3333 1.07953 11.0795 0.333336 12 0.333336C12.9205 0.333336 13.6666 1.07953 13.6666 2C13.6666 2.92048 12.9205 3.66667 12 3.66667C11.0795 3.66667 10.3333 2.92048 10.3333 2Z"
      />
      <path
        id="Vector_4"
        d="M0.333313 7C0.333313 6.07953 1.0795 5.33334 1.99998 5.33334C2.92045 5.33334 3.66665 6.07953 3.66665 7C3.66665 7.92048 2.92045 8.66667 1.99998 8.66667C1.0795 8.66667 0.333313 7.92048 0.333313 7Z"
      />
      <path
        id="Vector_5"
        d="M5.33331 7C5.33331 6.07953 6.0795 5.33334 6.99998 5.33334C7.92045 5.33334 8.66665 6.07953 8.66665 7C8.66665 7.92048 7.92045 8.66667 6.99998 8.66667C6.0795 8.66667 5.33331 7.92048 5.33331 7Z"
      />
      <path
        id="Vector_6"
        d="M10.3333 7C10.3333 6.07953 11.0795 5.33334 12 5.33334C12.9205 5.33334 13.6666 6.07953 13.6666 7C13.6666 7.92048 12.9205 8.66667 12 8.66667C11.0795 8.66667 10.3333 7.92048 10.3333 7Z"
      />
      <path
        id="Vector_7"
        d="M0.333313 12C0.333313 11.0795 1.0795 10.3333 1.99998 10.3333C2.92045 10.3333 3.66665 11.0795 3.66665 12C3.66665 12.9205 2.92045 13.6667 1.99998 13.6667C1.0795 13.6667 0.333313 12.9205 0.333313 12Z"
      />
      <path
        id="Vector_8"
        d="M5.33331 12C5.33331 11.0795 6.07951 10.3333 6.99998 10.3333C7.92045 10.3333 8.66665 11.0795 8.66665 12C8.66665 12.9205 7.92045 13.6667 6.99998 13.6667C6.07951 13.6667 5.33331 12.9205 5.33331 12Z"
      />
      <path
        id="Vector_9"
        d="M10.3333 12C10.3333 11.0795 11.0795 10.3333 12 10.3333C12.9205 10.3333 13.6666 11.0795 13.6666 12C13.6666 12.9205 12.9205 13.6667 12 13.6667C11.0795 13.6667 10.3333 12.9205 10.3333 12Z"
      />
    </g>
  </svg>
);
