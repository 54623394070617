import { ReactNode } from "react";

type Props = {
  dt: string;
  children: ReactNode;
};
export const DataListItem = ({ dt, children }: Props) => {
  const id = dt.replaceAll(" ", "_");
  return (
    <div className="grid grid-cols-3 items-center p-4">
      <dt id={id} className="text-sm font-medium leading-6 text-[#383689]">
        {dt}
      </dt>
      <dd
        aria-labelledby={id}
        className="col-span-2 break-words text-sm leading-6 text-[#383689]"
      >
        {children}
      </dd>
    </div>
  );
};
