/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * What state a node is in
 * @export
 */
export const NodeStatus = {
    OK: 'OK',
    ERROR: 'ERROR',
    UNAVAILABLE: 'UNAVAILABLE'
} as const;
export type NodeStatus = typeof NodeStatus[keyof typeof NodeStatus];


export function instanceOfNodeStatus(value: any): boolean {
    for (const key in NodeStatus) {
        if (Object.prototype.hasOwnProperty.call(NodeStatus, key)) {
            if (NodeStatus[key as keyof typeof NodeStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function NodeStatusFromJSON(json: any): NodeStatus {
    return NodeStatusFromJSONTyped(json, false);
}

export function NodeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeStatus {
    return json as NodeStatus;
}

export function NodeStatusToJSON(value?: NodeStatus | null): any {
    return value as any;
}

