export const SyncroomLogoTextIcon = () => (
  <svg
    width="222"
    height="56"
    viewBox="0 0 222 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2533_28)">
      <path
        d="M10.0163 13.3152C10.4268 11.7448 11.6139 10.7013 12.9742 10.3551C12.9989 10.3501 13.0187 10.3401 13.0434 10.3351L17.5643 9.08583L27.6548 6.30138L21.1652 4.56549L5.12931 0.275936C2.09228 -0.536821 -0.677646 2.29278 0.148386 5.37323L6.20761 27.9548L6.72202 25.9832L10.0163 13.3152Z"
        fill="url(#paint0_linear_2533_28)"
      />
      <path
        d="M55.0622 50.6268L50.4473 33.4384L49.8835 31.3413L48.9981 28.0452L45.1944 42.6848C44.7838 44.2551 43.5967 45.2987 42.2365 45.6449C42.2117 45.6499 42.192 45.6599 42.1672 45.6649L37.6463 46.9142L27.5558 49.6986L50.0764 55.719C53.1183 56.5318 55.8833 53.7022 55.0573 50.6217L55.0622 50.6268Z"
        fill="url(#paint1_linear_2533_28)"
      />
      <path
        d="M42.0832 10.1595C43.6314 10.5759 44.6602 11.78 45.0015 13.1596C45.0064 13.1847 45.0163 13.2048 45.0213 13.2299L46.2529 17.8154L48.9981 28.0502L54.9337 5.20767C55.735 2.1222 52.9452 -0.682318 49.9082 0.155525L32.9622 4.83641L30.8946 5.40835L27.6449 6.30639L29.5888 6.82816L42.0782 10.1695L42.0832 10.1595Z"
        fill="url(#paint2_linear_2533_28)"
      />
      <path
        d="M10.2092 42.8454C10.2092 42.8454 10.1943 42.8002 10.1894 42.7751L8.95776 38.1896L6.21257 27.9548L4.50114 34.5372L0.272055 50.7923C-0.529247 53.8778 2.26047 56.6873 5.2975 55.8495L27.5608 49.7036L13.1275 45.8455C11.5793 45.4291 10.5505 44.225 10.2092 42.8454Z"
        fill="url(#paint3_linear_2533_28)"
      />
      <path
        d="M42.1672 45.6699C42.1672 45.6699 42.2118 45.6549 42.2365 45.6499C43.5967 45.3037 44.7838 44.2602 45.1944 42.6898L48.9981 28.0502L46.2529 17.8154L45.0213 13.2299C45.0213 13.2299 45.0064 13.1847 45.0015 13.1596C44.6602 11.78 43.6313 10.5759 42.0832 10.1595L29.5937 6.81813L27.6498 6.29636L17.5594 9.0808L13.0385 10.33C13.0385 10.33 12.9939 10.3451 12.9692 10.3501C11.609 10.6963 10.4219 11.7398 10.0113 13.3102L6.71708 25.9781L6.20267 27.9498L8.94787 38.1845L10.1795 42.7701C10.1795 42.7701 10.1943 42.8153 10.1993 42.8403C10.5406 44.22 11.5694 45.4241 13.1176 45.8405L27.5509 49.6986L37.6414 46.9142L42.1623 45.6649L42.1672 45.6699ZM33.9415 42.3186C29.02 41.4055 24.0588 41.8721 19.157 42.5895C15.3781 42.6848 12.3509 38.7515 13.2709 35.0339C13.998 30.2025 13.9783 25.3008 13.2165 20.4745C12.4944 16.4207 16.397 12.6228 20.359 13.5259C25.1717 14.439 30.0636 14.439 34.8863 13.5259C37.587 13.0141 40.4064 14.4089 41.5836 17.1682C42.0535 18.277 42.1326 19.5112 41.9496 20.7052C41.1978 25.6069 41.2176 30.5787 41.9991 35.4754C42.83 40.0459 38.1607 43.6983 33.9366 42.3136L33.9415 42.3186Z"
        fill="url(#paint4_linear_2533_28)"
      />
      <path
        d="M42.1672 45.6699C42.1672 45.6699 42.2118 45.6549 42.2365 45.6499C43.5967 45.3037 44.7838 44.2602 45.1944 42.6898L48.9981 28.0502L46.2529 17.8154L45.0213 13.2299C45.0213 13.2299 45.0064 13.1847 45.0015 13.1596C44.6602 11.78 43.6313 10.5759 42.0832 10.1595L29.5937 6.81813L27.6498 6.29636L17.5594 9.0808L13.0385 10.33C13.0385 10.33 12.9939 10.3451 12.9692 10.3501C11.609 10.6963 10.4219 11.7398 10.0113 13.3102L6.71708 25.9781L6.20267 27.9498L8.94787 38.1845L10.1795 42.7701C10.1795 42.7701 10.1943 42.8153 10.1993 42.8403C10.5406 44.22 11.5694 45.4241 13.1176 45.8405L27.5509 49.6986L37.6414 46.9142L42.1623 45.6649L42.1672 45.6699ZM33.9415 42.3186C29.02 41.4055 24.0588 41.8721 19.157 42.5895C15.3781 42.6848 12.3509 38.7515 13.2709 35.0339C13.998 30.2025 13.9783 25.3008 13.2165 20.4745C12.4944 16.4207 16.397 12.6228 20.359 13.5259C25.1717 14.439 30.0636 14.439 34.8863 13.5259C37.587 13.0141 40.4064 14.4089 41.5836 17.1682C42.0535 18.277 42.1326 19.5112 41.9496 20.7052C41.1978 25.6069 41.2176 30.5787 41.9991 35.4754C42.83 40.0459 38.1607 43.6983 33.9366 42.3136L33.9415 42.3186Z"
        fill="url(#paint5_linear_2533_28)"
      />
      <path
        d="M75.4163 46.0864C73.1904 46.0864 71.2911 45.6148 69.7181 44.6716C68.1403 43.7284 67.0372 42.439 66.3992 40.7984L69.1196 39.3836C70.0495 41.9875 72.1715 43.2869 75.4855 43.2869C77.1178 43.2869 78.3643 42.9858 79.2299 42.3788C80.0905 41.7767 80.5209 40.969 80.5209 39.9656C80.5209 38.9622 80.0905 38.1745 79.2299 37.6578C78.3692 37.146 76.9249 36.6042 74.9068 36.0272C73.9077 35.7413 73.1459 35.5105 72.6266 35.3349C72.1023 35.1593 71.4543 34.8934 70.6678 34.5372C69.8863 34.181 69.3026 33.8097 68.9168 33.4284C68.531 33.0471 68.1848 32.5504 67.8781 31.9384C67.5714 31.3213 67.4181 30.6289 67.4181 29.8463C67.4181 27.9799 68.1452 26.4999 69.5945 25.4062C71.0437 24.3125 72.8145 23.7606 74.9019 23.7606C76.7864 23.7606 78.4236 24.1921 79.8185 25.05C81.2133 25.9079 82.262 27.0468 82.9643 28.4616L80.3082 29.8463C79.2892 27.6338 77.4838 26.525 74.8969 26.525C73.6059 26.525 72.5623 26.811 71.7659 27.3879C70.9695 27.9599 70.5738 28.7425 70.5738 29.7259C70.5738 30.7092 70.9498 31.3865 71.6967 31.8782C72.4435 32.3698 73.7395 32.8816 75.5746 33.4134C76.2324 33.599 76.6974 33.7294 76.9694 33.8147C77.2415 33.895 77.6668 34.0405 78.2456 34.2462C78.8243 34.4519 79.2497 34.6175 79.5217 34.7379C79.7938 34.8633 80.1647 35.0339 80.6297 35.2596C81.0946 35.4854 81.4409 35.7011 81.6684 35.9068C81.8959 36.1125 82.163 36.3634 82.4697 36.6594C82.7764 36.9554 82.999 37.2564 83.1325 37.5675C83.271 37.8735 83.3897 38.2297 83.4886 38.6261C83.5925 39.0274 83.642 39.4488 83.642 39.9004C83.642 41.7868 82.8803 43.2869 81.3617 44.4057C79.8432 45.5245 77.8548 46.0813 75.4064 46.0813L75.4163 46.0864Z"
        fill="#161638"
      />
      <path
        d="M97.2839 30.3179H100.45L93.5049 46.6382C92.7778 48.3791 91.7638 49.7087 90.458 50.6167C89.1522 51.5298 87.6485 51.9312 85.947 51.8309V49.3424C88.0343 49.4879 89.5677 48.4393 90.5421 46.2068L90.8487 45.6248L83.4293 30.3179H86.5949L92.3821 42.3688L97.2839 30.3179Z"
        fill="#161638"
      />
      <path
        d="M110.56 29.9165C112.558 29.9165 114.156 30.4834 115.358 31.6223C116.56 32.7612 117.163 34.3014 117.163 36.248V45.685H114.2V36.4035C114.2 35.1543 113.824 34.186 113.077 33.4987C112.331 32.8113 111.297 32.4702 109.981 32.4702C108.507 32.4702 107.315 32.8866 106.41 33.7144C105.505 34.5422 105.05 35.8215 105.05 37.5424V45.69H102.087V30.3229H105.05V32.5354C106.252 30.7945 108.092 29.9215 110.565 29.9215L110.56 29.9165Z"
        fill="#161638"
      />
      <path
        d="M128.515 46.0864C125.953 46.0864 123.821 45.3137 122.119 43.7635C120.418 42.2182 119.567 40.2967 119.567 37.9989C119.567 35.7011 120.418 33.7846 122.119 32.2344C123.821 30.6891 125.953 29.9115 128.515 29.9115C130.192 29.9115 131.7 30.2777 133.041 31.0052C134.381 31.7327 135.375 32.711 136.033 33.9402L133.55 35.2295C133.12 34.4118 132.457 33.7545 131.562 33.2629C130.666 32.7712 129.653 32.5254 128.515 32.5254C126.813 32.5254 125.389 33.0471 124.246 34.0907C123.099 35.1342 122.53 36.4386 122.53 37.9939C122.53 39.5492 123.104 40.8235 124.246 41.867C125.394 42.9106 126.813 43.4324 128.515 43.4324C129.648 43.4324 130.671 43.1915 131.577 42.7099C132.482 42.2283 133.174 41.5761 133.654 40.7583L136.172 42.0778C135.445 43.2869 134.401 44.2552 133.041 44.9826C131.68 45.7101 130.172 46.0713 128.515 46.0713V46.0864Z"
        fill="#161638"
      />
      <path
        d="M152.772 45.685L147.429 37.4471H141.815V45.685H138.65V24.167H148.176C150.219 24.167 151.965 24.8192 153.419 26.1186C154.869 27.418 155.596 28.9934 155.596 30.8346C155.596 32.2294 155.136 33.4987 154.216 34.6476C153.296 35.7965 152.114 36.6042 150.659 37.0758L156.273 45.685H152.767H152.772ZM141.815 26.8411V34.8633H148.181C149.359 34.8633 150.363 34.4669 151.194 33.6793C152.02 32.8916 152.435 31.9434 152.435 30.8346C152.435 29.7259 152.02 28.7877 151.194 28.005C150.363 27.2274 149.363 26.8361 148.181 26.8361H141.815V26.8411Z"
        fill="#161638"
      />
      <path
        d="M172.379 43.7484C170.633 45.3037 168.511 46.0864 166.013 46.0864C163.515 46.0864 161.403 45.3087 159.667 43.7484C157.931 42.1932 157.065 40.2767 157.065 37.9989C157.065 35.7212 157.931 33.8097 159.667 32.2494C161.403 30.6941 163.52 29.9115 166.013 29.9115C168.506 29.9115 170.628 30.6891 172.379 32.2494C174.125 33.8047 175 35.7212 175 37.9989C175 40.2767 174.125 42.1881 172.379 43.7484ZM166.013 43.4725C167.714 43.4725 169.144 42.9507 170.301 41.9072C171.459 40.8636 172.037 39.5592 172.037 38.0039C172.037 36.4487 171.459 35.1442 170.301 34.1007C169.144 33.0572 167.714 32.5354 166.013 32.5354C164.311 32.5354 162.916 33.0572 161.759 34.1007C160.602 35.1442 160.023 36.4487 160.023 38.0039C160.023 39.5592 160.602 40.8636 161.759 41.9072C162.916 42.9507 164.331 43.4725 166.013 43.4725Z"
        fill="#161638"
      />
      <path
        d="M191.981 43.7484C190.235 45.3037 188.113 46.0864 185.615 46.0864C183.117 46.0864 181.005 45.3087 179.269 43.7484C177.533 42.1932 176.667 40.2767 176.667 37.9989C176.667 35.7212 177.533 33.8097 179.269 32.2494C181.005 30.6941 183.122 29.9115 185.615 29.9115C188.108 29.9115 190.23 30.6891 191.981 32.2494C193.727 33.8047 194.602 35.7212 194.602 37.9989C194.602 40.2767 193.727 42.1881 191.981 43.7484ZM185.615 43.4725C187.317 43.4725 188.746 42.9507 189.903 41.9072C191.061 40.8636 191.64 39.5592 191.64 38.0039C191.64 36.4487 191.061 35.1442 189.903 34.1007C188.746 33.0572 187.317 32.5354 185.615 32.5354C183.913 32.5354 182.519 33.0572 181.361 34.1007C180.204 35.1442 179.625 36.4487 179.625 38.0039C179.625 39.5592 180.204 40.8636 181.361 41.9072C182.519 42.9507 183.933 43.4725 185.615 43.4725Z"
        fill="#161638"
      />
      <path
        d="M215.599 29.9165C217.504 29.9165 219.047 30.4734 220.229 31.5922C221.406 32.711 222 34.2211 222 36.1276V45.685H219.037V36.2179C219.037 35.0288 218.696 34.1057 218.018 33.4535C217.336 32.7963 216.43 32.4702 215.298 32.4702C214.027 32.4702 213.013 32.8565 212.251 33.6241C211.489 34.3917 211.113 35.5657 211.113 37.146V45.69H208.15V36.2229C208.15 35.0339 207.829 34.1107 207.181 33.4585C206.533 32.8013 205.652 32.4752 204.544 32.4752C203.298 32.4752 202.259 32.8665 201.428 33.6441C200.597 34.4218 200.187 35.5908 200.187 37.146V45.69H197.224V30.3229H200.187V32.3849C201.344 30.7443 203.036 29.9265 205.257 29.9265C207.478 29.9265 209.214 30.7995 210.257 32.5404C211.435 30.7995 213.22 29.9265 215.599 29.9265V29.9165Z"
        fill="#161638"
      />
      <path
        d="M69.7973 15.8588C70.2326 15.8588 70.5986 15.7484 70.8855 15.5277C71.1723 15.3069 71.3554 15.0159 71.4246 14.6547H72.5177C72.404 15.2367 72.1121 15.7233 71.6373 16.1147C71.1625 16.506 70.5491 16.7017 69.7973 16.7017C68.897 16.7017 68.1897 16.3956 67.6704 15.7836C67.151 15.1715 66.8938 14.3487 66.8938 13.3152C66.8938 12.372 67.151 11.5994 67.6654 10.9973C68.1798 10.4003 68.8773 10.0993 69.7626 10.0993C70.4502 10.0993 71.0339 10.2899 71.5037 10.6762C71.9786 11.0625 72.2952 11.5943 72.4584 12.2767C72.5375 12.6128 72.582 13.0593 72.582 13.6212H67.9671C67.9919 14.3587 68.1699 14.9206 68.5063 15.2969C68.8426 15.6732 69.273 15.8638 69.8022 15.8638L69.7973 15.8588ZM69.7626 10.9321C69.2631 10.9321 68.8525 11.0926 68.536 11.4187C68.2194 11.7448 68.0314 12.2014 67.977 12.7934H71.5235C71.4642 12.1964 71.2812 11.7348 70.9646 11.4137C70.648 11.0926 70.2474 10.9321 69.7626 10.9321Z"
        fill="#161638"
      />
      <path
        d="M78.389 10.2698L76.2868 13.275L78.5819 16.5261H77.2959L75.6438 14.0276H75.6191L73.967 16.5261H72.6909L74.986 13.275L72.8838 10.2698H74.1451L75.6141 12.5125H75.6389L77.1228 10.2698H78.3841H78.389Z"
        fill="#161638"
      />
      <path
        d="M81.5447 10.0943C82.3114 10.0943 82.9099 10.3 83.3353 10.7063C83.7607 11.1127 83.9734 11.7198 83.9734 12.5225V14.9256C83.9734 15.5578 84.0228 16.0896 84.1168 16.5261H83.1473C83.0929 16.2652 83.0632 15.9592 83.0632 15.613H83.0385C82.5934 16.3103 81.8811 16.6615 80.9017 16.6615C80.2686 16.6615 79.7641 16.491 79.3832 16.1498C79.0023 15.8086 78.8144 15.3671 78.8144 14.8253C78.8144 14.2835 78.9924 13.852 79.3436 13.5359C79.6948 13.2199 80.3032 12.9941 81.1589 12.8536C81.7574 12.7533 82.351 12.683 82.9445 12.6429V12.3971C82.9445 11.8954 82.8209 11.5291 82.5736 11.2883C82.3263 11.0525 81.985 10.9321 81.5497 10.9321C81.1144 10.9321 80.7731 11.0475 80.5307 11.2733C80.2884 11.499 80.1598 11.8 80.14 12.1763H79.0716C79.1062 11.5592 79.3337 11.0575 79.7641 10.6712C80.1944 10.2849 80.788 10.0943 81.5497 10.0943H81.5447ZM82.9396 13.852V13.4707C82.3164 13.5209 81.7772 13.5861 81.3222 13.6664C80.8028 13.7466 80.4368 13.8721 80.2241 14.0376C80.0114 14.2032 79.9026 14.439 79.9026 14.75C79.9026 15.0762 80.0114 15.337 80.234 15.5327C80.4566 15.7234 80.7682 15.8237 81.1639 15.8237C81.708 15.8237 82.1333 15.6581 82.44 15.332C82.628 15.1364 82.7566 14.9307 82.8308 14.7199C82.905 14.5092 82.9396 14.2233 82.9396 13.852Z"
        fill="#161638"
      />
      <path
        d="M86.5603 7.75632V16.5261H85.4919V7.75632H86.5603Z"
        fill="#161638"
      />
      <path
        d="M90.4629 10.0943C91.2296 10.0943 91.8281 10.3 92.2535 10.7063C92.6789 11.1127 92.8916 11.7198 92.8916 12.5225V14.9256C92.8916 15.5578 92.941 16.0896 93.035 16.5261H92.0655C92.0111 16.2652 91.9815 15.9592 91.9815 15.613H91.9567C91.5116 16.3103 90.7993 16.6615 89.8199 16.6615C89.1868 16.6615 88.6823 16.491 88.3014 16.1498C87.9205 15.8086 87.7326 15.3671 87.7326 14.8253C87.7326 14.2835 87.9106 13.852 88.2618 13.5359C88.613 13.2199 89.2214 12.9941 90.0771 12.8536C90.6756 12.7533 91.2692 12.683 91.8627 12.6429V12.3971C91.8627 11.8954 91.7391 11.5291 91.4918 11.2883C91.2445 11.0525 90.9032 10.9321 90.4679 10.9321C90.0326 10.9321 89.6913 11.0475 89.4489 11.2733C89.2066 11.499 89.078 11.8 89.0582 12.1763H87.9898C88.0244 11.5592 88.2519 11.0575 88.6823 10.6712C89.1126 10.2849 89.7061 10.0943 90.4679 10.0943H90.4629ZM91.8578 13.852V13.4707C91.2346 13.5209 90.6954 13.5861 90.2404 13.6664C89.721 13.7466 89.355 13.8721 89.1423 14.0376C88.9296 14.2032 88.8208 14.439 88.8208 14.75C88.8208 15.0762 88.9296 15.337 89.1522 15.5327C89.3748 15.7234 89.6864 15.8237 90.0821 15.8237C90.6262 15.8237 91.0515 15.6581 91.3582 15.332C91.5462 15.1364 91.6748 14.9307 91.749 14.7199C91.8232 14.5092 91.8578 14.2233 91.8578 13.852Z"
        fill="#161638"
      />
      <path
        d="M96.6211 15.6782C96.7892 15.6782 96.9376 15.6581 97.0563 15.618V16.506C96.8684 16.5562 96.6458 16.5813 96.3738 16.5813C95.7851 16.5813 95.3548 16.4408 95.0927 16.1648C94.8305 15.8839 94.697 15.4273 94.697 14.7852V11.1378H93.6286V10.2749H94.697V8.75972H95.7654V10.2749H96.992V11.1378H95.7654V14.6748C95.7654 15.036 95.8297 15.2969 95.9583 15.4524C96.0869 15.608 96.3045 15.6882 96.6161 15.6882L96.6211 15.6782Z"
        fill="#161638"
      />
      <path
        d="M100.45 15.8588C100.885 15.8588 101.251 15.7484 101.538 15.5277C101.825 15.3069 102.008 15.0159 102.077 14.6547H103.17C103.056 15.2367 102.764 15.7233 102.29 16.1147C101.815 16.506 101.201 16.7017 100.45 16.7017C99.5493 16.7017 98.842 16.3956 98.3226 15.7836C97.8032 15.1715 97.546 14.3487 97.546 13.3152C97.546 12.372 97.8032 11.5994 98.3177 10.9973C98.8321 10.4003 99.5295 10.0993 100.415 10.0993C101.102 10.0993 101.686 10.2899 102.156 10.6762C102.631 11.0625 102.947 11.5943 103.111 12.2767C103.19 12.6128 103.234 13.0593 103.234 13.6212H98.6194C98.6441 14.3587 98.8222 14.9206 99.1585 15.2969C99.4949 15.6732 99.9252 15.8638 100.454 15.8638L100.45 15.8588ZM100.415 10.9321C99.9153 10.9321 99.5048 11.0926 99.1882 11.4187C98.8716 11.7448 98.6837 12.2014 98.6293 12.7934H102.176C102.116 12.1964 101.933 11.7348 101.617 11.4137C101.3 11.0926 100.9 10.9321 100.415 10.9321Z"
        fill="#161638"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2533_28"
        x1="51.4946"
        y1="61.3635"
        x2="5.67854"
        y2="4.21016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F63EA" />
        <stop offset="0.94" stopColor="#407FFC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2533_28"
        x1="54.2022"
        y1="59.1898"
        x2="8.38478"
        y2="2.04129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F63EA" />
        <stop offset="0.94" stopColor="#407FFC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2533_28"
        x1="37.1"
        y1="-0.523825"
        x2="56.9822"
        y2="21.4276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F63EA" />
        <stop offset="0.94" stopColor="#407FFC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2533_28"
        x1="-1.29638"
        y1="34.2474"
        x2="18.5858"
        y2="56.1989"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F63EA" />
        <stop offset="0.94" stopColor="#407FFC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2533_28"
        x1="52.8515"
        y1="60.275"
        x2="7.0293"
        y2="3.12506"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F63EA" />
        <stop offset="0.94" stopColor="#407FFC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2533_28"
        x1="17.9026"
        y1="16.8594"
        x2="37.7833"
        y2="38.8157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F63EA" />
        <stop offset="0.94" stopColor="#407FFC" />
      </linearGradient>
      <clipPath id="clip0_2533_28">
        <rect width="222" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
