/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A representation of a Issue
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * The URN of an issue
     * @type {string}
     * @memberof Issue
     */
    issueUrn: string;
    /**
     * The issue type (User story, Bug, Epic) This depends on the ticket management system
     * @type {string}
     * @memberof Issue
     */
    type: string;
    /**
     * The summary/title of the issue
     * @type {string}
     * @memberof Issue
     */
    summary: string;
}

/**
 * Check if a given object implements the Issue interface.
 */
export function instanceOfIssue(value: object): value is Issue {
    if (!('issueUrn' in value) || value['issueUrn'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    return true;
}

export function IssueFromJSON(json: any): Issue {
    return IssueFromJSONTyped(json, false);
}

export function IssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Issue {
    if (json == null) {
        return json;
    }
    return {
        
        'issueUrn': json['issueUrn'],
        'type': json['type'],
        'summary': json['summary'],
    };
}

export function IssueToJSON(value?: Issue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issueUrn': value['issueUrn'],
        'type': value['type'],
        'summary': value['summary'],
    };
}

