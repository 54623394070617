import { Issue } from "../../../../../generated/syncroom-api/src";
import { useFormikContext } from "formik";
import { FormCheckboxInput } from "../../../../components/form/FormCheckboxInput";
import { FormValues } from "./IssueSelectionModal";

type Props = {
  issue: Issue;
};

export const IssueSelectionCheckbox = ({ issue }: Props) => {
  const formikContext = useFormikContext<FormValues>();
  const selectedIssuesFieldName = "selectedIssues";

  const onChangeIssues = async (issue: Issue, selected: boolean) => {
    if (selected)
      await formikContext.setFieldValue(selectedIssuesFieldName, [
        ...formikContext.values.selectedIssues,
        issue,
      ]);
    else
      await formikContext.setFieldValue(
        selectedIssuesFieldName,
        formikContext.values.selectedIssues.filter(
          (selectedIssue) => selectedIssue.issueUrn !== issue.issueUrn,
        ),
      );
  };

  return (
    <>
      <FormCheckboxInput
        name={selectedIssuesFieldName}
        value={issue.issueUrn}
        checked={formikContext.values.selectedIssues
          .map((selectedIssue) => selectedIssue.issueUrn)
          .includes(issue.issueUrn)}
        onChange={async (e) => {
          await onChangeIssues(issue, e.target.checked);
        }}
      >
        <div>
          <p className="text-base font-medium leading-relaxed">
            {issue.issueUrn}
          </p>
          <p className="text-neutral-greyer_grey text-sm font-medium leading-normal">
            {issue.type}, {issue.summary}
          </p>
        </div>
      </FormCheckboxInput>
    </>
  );
};
