import { Chip, ChipColor } from "./Chip";
import { FaCircleExclamation } from "react-icons/fa6";

type Props = {
  totalErrors?: number;
  showOnlyTotal?: boolean;
};

export const ErrorChip = ({ totalErrors, showOnlyTotal = false }: Props) => {
  return (
    <Chip
      color={ChipColor.RED}
      leadingIcon={<FaCircleExclamation title="Error" />}
    >
      {totalErrors && (
        <>
          {totalErrors}
          {showOnlyTotal ? "" : ` error${totalErrors > 1 ? "s" : ""}`}
        </>
      )}
    </Chip>
  );
};
