import React from "react";
import { FieldInputProps } from "formik";

type Props = {
  input: FieldInputProps<any>;
  disabled: boolean;
};

export const ToggleBase = ({ input, disabled }: Props) => {
  const enabledColours =
    "before:bg-neutral-white bg-neutral-500 peer-checked:bg-secondary";
  const disabledColours = "before:bg-gray-100 bg-neutral-200";

  return (
    <label
      className={`relative inline-block h-7 w-10 ${disabled ? "cursor-default" : "cursor-pointer"}`}
    >
      <input
        type="checkbox"
        className="peer h-0 w-0 opacity-0"
        {...input}
        disabled={disabled}
      />
      <span
        className={
          "absolute" +
          " bottom-0" +
          " left-0" +
          " right-0" +
          " top-0" +
          " rounded-full" +
          " before:absolute" +
          " before:bottom-[4px]" +
          " before:left-[4px]" +
          " before:h-[20px]" +
          " before:w-[20px]" +
          " before:rounded-full" +
          " before:duration-[.4s]" +
          " peer-checked:before:translate-x-[12px]" +
          ` ${disabled ? disabledColours : enabledColours}`
        }
      />
    </label>
  );
};
