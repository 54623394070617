import { Form, Formik } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useNodeApi } from "../../../shared/useNodeApi";
import { Modal } from "../../../components/Modal";
import { FormTextInput } from "../../../components/form/FormTextInput";
import React from "react";
import { Node } from "../../../../generated/syncroom-api/src/";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { NodeTagsEditor } from "./NodeTagsEditor";
import { FormLabel } from "../../../components/form/FormLabel";

type Props = {
  nodeInfo: Node;
  toggleModal: () => void;
};

type FormValues = {
  customName: string;
  tags: string[];
  url: string;
  id: string;
};

export const EditNodeDetailsModal = ({ nodeInfo, toggleModal }: Props) => {
  const nodesApi = useNodeApi();
  const queryClient = useQueryClient();

  const updateNodeMutation = useMutation({
    mutationFn: (values: FormValues) =>
      nodesApi.updateNode({
        nodeId: nodeInfo.id,
        updateNodeRequest: {
          customName: values.customName,
          tags: values.tags,
        },
      }),
    onSuccess: async () => {
      toggleModal();
      await queryClient.invalidateQueries({
        queryKey: ["nodeInfo", nodeInfo.id],
      });
    },
  });

  const updateNodeDetailsValidationScheme = yup.object({
    customName: yup.string().required("Node name is a required field").trim(),
    tags: yup.array().optional().default([]),
    url: yup.string().optional().default(""),
    id: yup.string().optional().default(""),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customName: nodeInfo.customName,
        tags: nodeInfo.tags,
      }}
      validationSchema={updateNodeDetailsValidationScheme}
      onSubmit={async (values) =>
        updateNodeMutation.mutate(
          await updateNodeDetailsValidationScheme.validate(values),
        )
      }
    >
      <Form noValidate className="h-full">
        <Modal
          title="Edit node"
          toggleModal={() => toggleModal()}
          buttons={
            <>
              <Button onClick={() => toggleModal()} type={ButtonType.SECONDARY}>
                Cancel
              </Button>
              <Button buttonType="submit">Submit</Button>
            </>
          }
        >
          <div className="grid grid-cols-1 gap-3">
            <FormTextInput label="Name" name="customName" />
            <div>
              <FormLabel>Tags</FormLabel>
              <NodeTagsEditor name="tags" />
            </div>
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
