import { Button, ButtonType } from "../../../../components/buttons/Button";
import { FullScreenModal } from "../../../../components/FullScreenModal";
import { FaArrowLeftLong } from "react-icons/fa6";
import React, { useState } from "react";
import { StartTestRun } from "./StartTestRun";
import { TestRunInProgress } from "./TestRunInProgress";
import { TestRunResults } from "./TestRunResults";
import {
  ConnectionDetail,
  ConnectionVersion,
  Issue,
  NodeSupportedFeaturesEnum,
  type Scripts,
  StartTestRunIncoming200ResponseInner,
  StartTestRunOutgoing200ResponseInner,
  UpdateConnectionScripts200Response,
} from "../../../../../generated/syncroom-api/src";
import { ScriptEditorSideBySide } from "../ScriptEditorSideBySide";
import { useNodeApi } from "../../../../shared/useNodeApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNodeInfoQuery } from "../../../../nodes/useNodeInfoQuery";
import { Loader } from "../../../../components/Loader";
import { ActivateVersionModal } from "../ActivateVersionModal";
import { ErrorBanner } from "../../../../components/banner/ErrorBanner";

type Props = {
  nodeId: string;
  connection: ConnectionDetail;
  selectedVersion: ConnectionVersion;
  scripts: Scripts;
  onClose: () => void;
  queryKey: (string | undefined)[];
};

export enum TestRunState {
  EDIT_SCRIPT,
  SELECT_ISSUES,
  IN_PROGRESS,
  RESULTS,
}

export type TestRunResult = {
  outgoingResults: StartTestRunOutgoing200ResponseInner[];
  incomingResults: StartTestRunIncoming200ResponseInner[];
};

export const TestRunModal = ({
  onClose,
  selectedVersion,
  connection,
  scripts,
  nodeId,
  queryKey,
}: Props) => {
  const [state, setState] = useState<TestRunState>(TestRunState.EDIT_SCRIPT);
  const [issueSelection, setIssueSelection] = useState<Issue[]>([]);
  const [outgoingResults, setOutgoingResults] =
    useState<StartTestRunOutgoing200ResponseInner[]>();
  const [incomingResults, setIncomingResults] =
    useState<StartTestRunIncoming200ResponseInner[]>();
  const [scriptsState, setScriptsState] = useState<Scripts>(scripts);
  const nodeInfoSource = useNodeInfoQuery(connection.sourceNode.id);
  const nodeInfoTarget = useNodeInfoQuery(connection.targetNode.id);
  const [incomingErrorState, setIncomingErrorState] = useState<string>();
  const [outgoingErrorState, setOutgoingErrorState] = useState<string>();
  const [isActivateVersionModalOpen, setIsActivateVersionModalOpen] =
    useState(false);

  const api = useNodeApi();
  const queryClient = useQueryClient();

  const onSuccessBuilder =
    (
      validScriptsFunc: () => any,
    ): ((data: UpdateConnectionScripts200Response) => Promise<void>) =>
    async (data: UpdateConnectionScripts200Response): Promise<void> => {
      if (data.outgoing.isValid && data.incoming.isValid) {
        validScriptsFunc();
      } else {
        toast.error("Could not save script since it contains errors");
        setIncomingErrorState(data.incoming.errors);
        setOutgoingErrorState(data.outgoing.errors);
      }
    };

  const updateScriptsMutation = useMutation({
    mutationFn: (scripts: Scripts) =>
      api.updateConnectionScripts({
        nodeId,
        connectionId: connection.id,
        versionId: selectedVersion?.id!,
        scripts,
      }),
  });

  let content;
  if (nodeInfoSource.isPending || nodeInfoTarget.isPending) {
    content = <Loader />;
  } else if (state === TestRunState.EDIT_SCRIPT) {
    content = (
      <ScriptEditorSideBySide
        connection={connection}
        scripts={scriptsState}
        updateScripts={(updatedScripts) => setScriptsState(updatedScripts)}
        incomingScriptError={incomingErrorState}
        outgoingScriptError={outgoingErrorState}
      />
    );
  } else if (state === TestRunState.SELECT_ISSUES) {
    content = (
      <StartTestRun
        sourceNodeId={connection.sourceNode.id}
        connectionId={connection.id}
        versionId={selectedVersion.id}
        issueSelection={issueSelection}
        onStart={(issues) => {
          setIssueSelection(issues);
          setState(TestRunState.IN_PROGRESS);
        }}
      />
    );
  } else if (state === TestRunState.IN_PROGRESS) {
    content = (
      <TestRunInProgress
        sourceNodeId={connection.sourceNode.id}
        targetNodeId={connection.targetNode.id}
        connectionId={connection.id}
        versionId={selectedVersion.id}
        issues={issueSelection}
        setOutgoingResults={(
          outgoingResults: StartTestRunOutgoing200ResponseInner[],
        ) => setOutgoingResults(outgoingResults)}
        setIncomingResults={(
          incomingResults: StartTestRunIncoming200ResponseInner[],
        ) => setIncomingResults(incomingResults)}
        onProgress={() => {
          setState(TestRunState.RESULTS);
        }}
      />
    );
  } else if (state === TestRunState.RESULTS) {
    if (
      nodeInfoSource.data === undefined ||
      nodeInfoTarget.data === undefined ||
      outgoingResults === undefined ||
      incomingResults === undefined
    ) {
      content = (
        <ErrorBanner>
          Test Run reached an illegal state, please reload the page. If this
          problem persists contact an admin
        </ErrorBanner>
      );
    } else {
      content = (
        <TestRunResults
          sourceNode={nodeInfoSource.data}
          targetNode={nodeInfoTarget.data}
          issueSelection={issueSelection}
          outgoingResults={outgoingResults}
          incomingResults={incomingResults}
        />
      );
    }
  }

  const enableTestRun =
    nodeInfoSource.data?.supportedFeatures?.includes(
      NodeSupportedFeaturesEnum.DRAFT_MODE,
    ) &&
    nodeInfoTarget.data?.supportedFeatures?.includes(
      NodeSupportedFeaturesEnum.DRAFT_MODE,
    );

  return (
    <FullScreenModal
      title={state === TestRunState.EDIT_SCRIPT ? "Scripts" : "Back to editing"}
      subHeader={"Version " + selectedVersion.name}
      onClose={() => onClose()}
      leftButtons={
        state !== TestRunState.EDIT_SCRIPT && (
          <Button
            type={ButtonType.SECONDARY}
            onClick={() => setState(TestRunState.EDIT_SCRIPT)}
            iconLeft={<FaArrowLeftLong />}
            label="Back to editing"
          />
        )
      }
      rightButtons={
        <>
          {state === TestRunState.EDIT_SCRIPT &&
            enableTestRun &&
            updateScriptsMutation.isPending && <Loader />}
          {state === TestRunState.EDIT_SCRIPT && enableTestRun && (
            <Button
              type={ButtonType.TERTIARY}
              onClick={() => {
                updateScriptsMutation.mutate(scriptsState, {
                  onSuccess: onSuccessBuilder(() =>
                    setState(TestRunState.SELECT_ISSUES),
                  ),
                });
              }}
              disabled={updateScriptsMutation.isPending}
            >
              Start Test Run
            </Button>
          )}
          {state === TestRunState.EDIT_SCRIPT && (
            <Button
              onClick={() =>
                updateScriptsMutation.mutate(scriptsState, {
                  onSuccess: onSuccessBuilder(async () => {
                    await queryClient.invalidateQueries({ queryKey });
                    onClose();
                  }),
                })
              }
              disabled={updateScriptsMutation.isPending}
            >
              Save Script
            </Button>
          )}
          {state === TestRunState.RESULTS && (
            <div className="space-x-2.5">
              <Button type={ButtonType.TERTIARY} onClick={() => onClose()}>
                Exit Test Run
              </Button>
              <Button
                onClick={() => {
                  setIsActivateVersionModalOpen(true);
                }}
              >
                Publish version
              </Button>
            </div>
          )}
        </>
      }
    >
      {isActivateVersionModalOpen && (
        <ActivateVersionModal
          setIsModalOpen={setIsActivateVersionModalOpen}
          onClose={() => {
            setIsActivateVersionModalOpen(false);
            onClose();
          }}
          nodeId={nodeId}
          connectionId={connection.id}
          versionId={selectedVersion.id}
        />
      )}
      {content}
    </FullScreenModal>
  );
};
