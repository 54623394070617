/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';
import type { NodeStatus } from './NodeStatus';
import {
    NodeStatusFromJSON,
    NodeStatusFromJSONTyped,
    NodeStatusToJSON,
} from './NodeStatus';

/**
 * The node response object
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * The unique identifier of a node in SyncRoom
     * @type {string}
     * @memberof Node
     */
    id: string;
    /**
     * The given name of a node
     * @type {string}
     * @memberof Node
     */
    customName: string;
    /**
     * 
     * @type {NodeType}
     * @memberof Node
     */
    type: NodeType;
    /**
     * 
     * @type {NodeStatus}
     * @memberof Node
     */
    status: NodeStatus;
    /**
     * The URL of the admin interface on the issue tracker itself
     * @type {string}
     * @memberof Node
     */
    adminUrl: string;
    /**
     * Amount of errors currently on the node
     * @type {number}
     * @memberof Node
     */
    totalErrors: number;
    /**
     * The software version that is running on the node
     * @type {string}
     * @memberof Node
     */
    version: string;
    /**
     * The raw software version that is running on the node
     * @type {string}
     * @memberof Node
     */
    rawVersion: string;
    /**
     * The unique identifier of a node from exalate
     * @type {string}
     * @memberof Node
     */
    instanceUid: string;
    /**
     * The URL of the issue tracker for which the node is responsible
     * @type {string}
     * @memberof Node
     */
    issueTrackerUrl: string;
    /**
     * A list of node id's this node is connected to
     * @type {Array<string>}
     * @memberof Node
     */
    connectedTo: Array<string>;
    /**
     * List of Tags
     * @type {Array<string>}
     * @memberof Node
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    baseUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Node
     */
    supportedFeatures: Array<NodeSupportedFeaturesEnum>;
}


/**
 * @export
 */
export const NodeSupportedFeaturesEnum = {
    DRAFT_MODE: 'DRAFT_MODE',
    VALIDATE_SCRIPT: 'VALIDATE_SCRIPT',
    VALIDATE_TRIGGER: 'VALIDATE_TRIGGER'
} as const;
export type NodeSupportedFeaturesEnum = typeof NodeSupportedFeaturesEnum[keyof typeof NodeSupportedFeaturesEnum];


/**
 * Check if a given object implements the Node interface.
 */
export function instanceOfNode(value: object): value is Node {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('customName' in value) || value['customName'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('adminUrl' in value) || value['adminUrl'] === undefined) return false;
    if (!('totalErrors' in value) || value['totalErrors'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('rawVersion' in value) || value['rawVersion'] === undefined) return false;
    if (!('instanceUid' in value) || value['instanceUid'] === undefined) return false;
    if (!('issueTrackerUrl' in value) || value['issueTrackerUrl'] === undefined) return false;
    if (!('connectedTo' in value) || value['connectedTo'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('baseUrl' in value) || value['baseUrl'] === undefined) return false;
    if (!('supportedFeatures' in value) || value['supportedFeatures'] === undefined) return false;
    return true;
}

export function NodeFromJSON(json: any): Node {
    return NodeFromJSONTyped(json, false);
}

export function NodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Node {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customName': json['customName'],
        'type': NodeTypeFromJSON(json['type']),
        'status': NodeStatusFromJSON(json['status']),
        'adminUrl': json['adminUrl'],
        'totalErrors': json['totalErrors'],
        'version': json['version'],
        'rawVersion': json['rawVersion'],
        'instanceUid': json['instanceUid'],
        'issueTrackerUrl': json['issueTrackerUrl'],
        'connectedTo': json['connectedTo'],
        'tags': json['tags'],
        'baseUrl': json['baseUrl'],
        'supportedFeatures': json['supportedFeatures'],
    };
}

export function NodeToJSON(value?: Node | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'customName': value['customName'],
        'type': NodeTypeToJSON(value['type']),
        'status': NodeStatusToJSON(value['status']),
        'adminUrl': value['adminUrl'],
        'totalErrors': value['totalErrors'],
        'version': value['version'],
        'rawVersion': value['rawVersion'],
        'instanceUid': value['instanceUid'],
        'issueTrackerUrl': value['issueTrackerUrl'],
        'connectedTo': value['connectedTo'],
        'tags': value['tags'],
        'baseUrl': value['baseUrl'],
        'supportedFeatures': value['supportedFeatures'],
    };
}

