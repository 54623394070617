/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartTestRunIncomingRequestInner
 */
export interface StartTestRunIncomingRequestInner {
    /**
     * 
     * @type {string}
     * @memberof StartTestRunIncomingRequestInner
     */
    issueUrn: string;
    /**
     * A representation of a Replica, nested key value pair. For Java this wil result in a Map<String, Object>
     * @type {{ [key: string]: any; }}
     * @memberof StartTestRunIncomingRequestInner
     */
    replica: { [key: string]: any; };
}

/**
 * Check if a given object implements the StartTestRunIncomingRequestInner interface.
 */
export function instanceOfStartTestRunIncomingRequestInner(value: object): value is StartTestRunIncomingRequestInner {
    if (!('issueUrn' in value) || value['issueUrn'] === undefined) return false;
    if (!('replica' in value) || value['replica'] === undefined) return false;
    return true;
}

export function StartTestRunIncomingRequestInnerFromJSON(json: any): StartTestRunIncomingRequestInner {
    return StartTestRunIncomingRequestInnerFromJSONTyped(json, false);
}

export function StartTestRunIncomingRequestInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartTestRunIncomingRequestInner {
    if (json == null) {
        return json;
    }
    return {
        
        'issueUrn': json['issueUrn'],
        'replica': json['replica'],
    };
}

export function StartTestRunIncomingRequestInnerToJSON(value?: StartTestRunIncomingRequestInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issueUrn': value['issueUrn'],
        'replica': value['replica'],
    };
}

