import { useParams } from "react-router-dom";
import { useConnectionDetailQuery } from "../connections/useConnectionDetailQuery";
import { Loader } from "../components/Loader";

export const ConnectionCrumb = () => {
  const { nodeId, connectionId } = useParams();
  if (!nodeId || !connectionId)
    throw new Error(
      "nodeId and connectionId should be a path param when using this crumb",
    );

  const connectionDetailQuery = useConnectionDetailQuery(nodeId, connectionId);

  if (connectionDetailQuery.isPending) return <Loader />;
  if (connectionDetailQuery.isError) return;

  return (
    <>
      {connectionDetailQuery.data.sourceNode.customName} to{" "}
      {connectionDetailQuery.data.targetNode.customName}
    </>
  );
};
