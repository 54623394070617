import React from "react";

interface LoaderPointProps {
  flip?: boolean;
}

const LoaderPoint = ({ flip = false }: LoaderPointProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: flip ? "rotate(180deg)" : "", position: "absolute" }}
  >
    <g>
      <path
        id="vector"
        d="M31.4985 16.5338C31.4985 16.1766 31.4778 15.8245 31.4374 15.4785C31.3666 14.8718 30.7222 14.5488 30.1579 14.7828L28.1628 15.6101C27.3401 15.9512 27.3401 17.1164 28.1628 17.4576L30.1579 18.2849C30.7222 18.5189 31.3666 18.1958 31.4374 17.5891C31.4778 17.2432 31.4985 16.891 31.4985 16.5338Z"
        fill="#00AD68"
      />
    </g>
  </svg>
);

const spinTiming = [
  "animate-loader-spin-1",
  "animate-loader-spin-2",
  "animate-loader-spin-3",
];

export const Loader = () => {
  return (
    <div className="flex size-full items-center justify-center">
      <span className="relative my-auto flex min-h-8 min-w-8">
        {spinTiming.map((animation) => (
          <div
            key={animation}
            className={`${animation} absolute inset-0 size-8`}
          >
            <LoaderPoint />
            <LoaderPoint flip />
          </div>
        ))}
      </span>
    </div>
  );
};
