import { Logo } from "./subcomponents/Logo";
import { SidebarItem } from "./subcomponents/SidebarItem";
import { LogoutItem } from "./subcomponents/LogoutItem";
import { NetworkIcon } from "../../icons/menu/NetworkIcon";
import { NodesIcon } from "../../icons/menu/NodesIcon";
import { Roles } from "../../shared/Roles";
import { GuardedComponent } from "../GuardedComponent";
import { UserIcon } from "../../icons/menu/UserIcon";

const sideBarItemsData = [
  {
    key: 1,
    icon: <NetworkIcon />,
    text: "Networks",
    to: "networks",
  },
  {
    key: 2,
    icon: <NodesIcon />,
    text: "Nodes",
    to: "nodes",
    role_allowed: Roles.SYSTEM_ADMIN,
  },
  {
    key: 3,
    icon: <UserIcon />,
    text: "Users",
    to: "users",
    role_allowed: Roles.SYSTEM_ADMIN,
  },
];

export const Sidebar = () => {
  const sideBarItems = sideBarItemsData.map((item) => {
    const sidebarItemElement = (
      <SidebarItem
        key={item.key}
        icon={item.icon}
        text={item.text}
        to={item.to}
      />
    );

    return item.role_allowed ? (
      <GuardedComponent
        role={item.role_allowed}
        key={item.key}
        redirectToAccessDenied={false}
      >
        {sidebarItemElement}
      </GuardedComponent>
    ) : (
      sidebarItemElement
    );
  });

  return (
    <div className="inline-flex items-start justify-center gap-2.5 p-10">
      <div className="inline-flex min-h-full shrink grow basis-0 flex-col items-start justify-between rounded-xl border border-zinc-200 bg-white px-5 pb-4 pt-5 shadow">
        <div className="flex flex-col items-center justify-start gap-10 self-stretch">
          <Logo />
          <div className="flex w-full flex-col gap-4">{sideBarItems}</div>
        </div>
        <div className="flex h-11 flex-col items-start justify-start gap-5 self-stretch">
          <LogoutItem />
        </div>
      </div>
    </div>
  );
};
