/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddNodeAndConnectionsToNetwork200ResponseInner,
  AddNodeAndConnectionsToNetworkRequest,
  CreateNetwork200Response,
  ErrorRest,
  FindNewNode200Response,
  Network,
  NetworkRequestBody,
  Node,
} from '../models/index';
import {
    AddNodeAndConnectionsToNetwork200ResponseInnerFromJSON,
    AddNodeAndConnectionsToNetwork200ResponseInnerToJSON,
    AddNodeAndConnectionsToNetworkRequestFromJSON,
    AddNodeAndConnectionsToNetworkRequestToJSON,
    CreateNetwork200ResponseFromJSON,
    CreateNetwork200ResponseToJSON,
    ErrorRestFromJSON,
    ErrorRestToJSON,
    FindNewNode200ResponseFromJSON,
    FindNewNode200ResponseToJSON,
    NetworkFromJSON,
    NetworkToJSON,
    NetworkRequestBodyFromJSON,
    NetworkRequestBodyToJSON,
    NodeFromJSON,
    NodeToJSON,
} from '../models/index';

export interface AddNodeAndConnectionsToNetworkOperationRequest {
    networkId: string;
    addNodeAndConnectionsToNetworkRequest: AddNodeAndConnectionsToNetworkRequest;
}

export interface CreateNetworkRequest {
    networkRequestBody: NetworkRequestBody;
}

export interface DeleteNetworkRequest {
    networkId: string;
}

export interface FindNewNodeRequest {
    networkId: string;
    url: string;
}

export interface GetNetworkInfoRequest {
    networkId: string;
}

export interface GetNodesInNetworkRequest {
    networkId: string;
}

export interface GetTagsUsedInNetworkRequest {
    networkId: string;
}

export interface UpdateNetworkRequest {
    networkId: string;
    networkRequestBody: NetworkRequestBody;
}

/**
 * 
 */
export class NetworksApi extends runtime.BaseAPI {

    /**
     * Add a set of connections between nodes to a network
     */
    async addNodeAndConnectionsToNetworkRaw(requestParameters: AddNodeAndConnectionsToNetworkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddNodeAndConnectionsToNetwork200ResponseInner>>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling addNodeAndConnectionsToNetwork().'
            );
        }

        if (requestParameters['addNodeAndConnectionsToNetworkRequest'] == null) {
            throw new runtime.RequiredError(
                'addNodeAndConnectionsToNetworkRequest',
                'Required parameter "addNodeAndConnectionsToNetworkRequest" was null or undefined when calling addNodeAndConnectionsToNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/networks/{network_id}/nodes`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNodeAndConnectionsToNetworkRequestToJSON(requestParameters['addNodeAndConnectionsToNetworkRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddNodeAndConnectionsToNetwork200ResponseInnerFromJSON));
    }

    /**
     * Add a set of connections between nodes to a network
     */
    async addNodeAndConnectionsToNetwork(requestParameters: AddNodeAndConnectionsToNetworkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddNodeAndConnectionsToNetwork200ResponseInner>> {
        const response = await this.addNodeAndConnectionsToNetworkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new network
     */
    async createNetworkRaw(requestParameters: CreateNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateNetwork200Response>> {
        if (requestParameters['networkRequestBody'] == null) {
            throw new runtime.RequiredError(
                'networkRequestBody',
                'Required parameter "networkRequestBody" was null or undefined when calling createNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/networks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NetworkRequestBodyToJSON(requestParameters['networkRequestBody']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateNetwork200ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new network
     */
    async createNetwork(requestParameters: CreateNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateNetwork200Response> {
        const response = await this.createNetworkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a network
     */
    async deleteNetworkRaw(requestParameters: DeleteNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling deleteNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/networks/{network_id}`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a network
     */
    async deleteNetwork(requestParameters: DeleteNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNetworkRaw(requestParameters, initOverrides);
    }

    /**
     * Find a new node to add to the network
     */
    async findNewNodeRaw(requestParameters: FindNewNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindNewNode200Response>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling findNewNode().'
            );
        }

        if (requestParameters['url'] == null) {
            throw new runtime.RequiredError(
                'url',
                'Required parameter "url" was null or undefined when calling findNewNode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['url'] != null) {
            queryParameters['url'] = requestParameters['url'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/networks/{network_id}/findNewNode`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindNewNode200ResponseFromJSON(jsonValue));
    }

    /**
     * Find a new node to add to the network
     */
    async findNewNode(requestParameters: FindNewNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindNewNode200Response> {
        const response = await this.findNewNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve information about a specific network
     */
    async getNetworkInfoRaw(requestParameters: GetNetworkInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Network>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling getNetworkInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/networks/{network_id}`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NetworkFromJSON(jsonValue));
    }

    /**
     * Retrieve information about a specific network
     */
    async getNetworkInfo(requestParameters: GetNetworkInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Network> {
        const response = await this.getNetworkInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all networks
     */
    async getNetworksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Network>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/networks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkFromJSON));
    }

    /**
     * Retrieve all networks
     */
    async getNetworks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Network>> {
        const response = await this.getNetworksRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all nodes within a specified network
     */
    async getNodesInNetworkRaw(requestParameters: GetNodesInNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Node>>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling getNodesInNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/networks/{network_id}/nodes`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeFromJSON));
    }

    /**
     * Retrieve all nodes within a specified network
     */
    async getNodesInNetwork(requestParameters: GetNodesInNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Node>> {
        const response = await this.getNodesInNetworkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all used tags of the nodes that are part of the network
     */
    async getTagsUsedInNetworkRaw(requestParameters: GetTagsUsedInNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling getTagsUsedInNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/networks/{network_id}/tags`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Retrieve all used tags of the nodes that are part of the network
     */
    async getTagsUsedInNetwork(requestParameters: GetTagsUsedInNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getTagsUsedInNetworkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a network
     */
    async updateNetworkRaw(requestParameters: UpdateNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['networkId'] == null) {
            throw new runtime.RequiredError(
                'networkId',
                'Required parameter "networkId" was null or undefined when calling updateNetwork().'
            );
        }

        if (requestParameters['networkRequestBody'] == null) {
            throw new runtime.RequiredError(
                'networkRequestBody',
                'Required parameter "networkRequestBody" was null or undefined when calling updateNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/networks/{network_id}`.replace(`{${"network_id"}}`, encodeURIComponent(String(requestParameters['networkId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NetworkRequestBodyToJSON(requestParameters['networkRequestBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a network
     */
    async updateNetwork(requestParameters: UpdateNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNetworkRaw(requestParameters, initOverrides);
    }

}
