import { useParams } from "react-router-dom";
import { NetworkDetail } from "./NetworkDetail";

export const NetworkDetailPage = () => {
  const { networkId } = useParams();

  if (!networkId) return <h2>Error</h2>;

  return <NetworkDetail networkId={networkId} />;
};
