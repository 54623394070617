import { NavLink } from "react-router-dom";
import { SyncroomLogoTextIcon } from "../../../icons/logo/SyncroomLogoTextIcon";

export const Logo = () => {
  return (
    <NavLink
      to="/"
      className="inline-flex h-10 items-center justify-center gap-2"
    >
      <div className="relative h-10">
        <SyncroomLogoTextIcon />
      </div>
    </NavLink>
  );
};
