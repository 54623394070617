import { Node } from "../../../../../generated/syncroom-api/src";
import { NodeTypeIndicator } from "../../../../components/node/NodeTypeIndicator";
import { Chip, ChipColor } from "../../../../components/Chip";

type Props = {
  node: Node;
  issueUrn?: string;
  type: TitleType;
  errorPresent: boolean;
};

export enum TitleType {
  INCOMING,
  OUTGOING,
  NO_RESPONSE,
}

export const ReplicaTitle = ({ node, issueUrn, type, errorPresent }: Props) => {
  return (
    <div className="border-neutral-lesser_grey flex items-center space-x-5 rounded-lg border-2 p-3">
      <div className="border-neutral-grey size-14 rounded-xl border p-1">
        <NodeTypeIndicator nodeType={node.type} />
      </div>
      <div className="my-auto w-full text-lg">
        {type === TitleType.INCOMING &&
          titleTextIncoming(errorPresent, issueUrn, node)}
        {type === TitleType.OUTGOING &&
          titleTextOutgoing(errorPresent, issueUrn, node)}
        {type === TitleType.NO_RESPONSE && (
          <p>No replica found for this entity</p>
        )}
      </div>
      <div className="pr-5">
        {type !== TitleType.NO_RESPONSE && !errorPresent && (
          <Chip color={ChipColor.BLUE}>{issueUrn}</Chip>
        )}
      </div>
    </div>
  );
};

const titleTextIncoming = (
  errorPresent: boolean,
  issueUrn: string | undefined,
  node: Node,
) =>
  errorPresent ? (
    <p>
      Error found in the replica of "{issueUrn}" on "{node.customName}" side
    </p>
  ) : (
    <p>
      Replica sent from "{issueUrn}" on "{node.customName}" side
    </p>
  );

const titleTextOutgoing = (
  errorPresent: boolean,
  issueUrn: string | undefined,
  node: Node,
) =>
  errorPresent ? (
    <p>Error found in replica on "{node.customName}" side</p>
  ) : (
    <p>
      Data applied on "{issueUrn}" on "{node.customName}" side
    </p>
  );
