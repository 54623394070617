export const LogoutIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Logout">
        <path
          id="Vector"
          d="M14.1667 5.83333L12.9917 7.00833L15.1417 9.16667L6.66669 9.16667V10.8333L15.1417 10.8333L12.9917 12.9833L14.1667 14.1667L18.3334 10M3.33335 4.16667L10 4.16667V2.5L3.33335 2.5C2.41669 2.5 1.66669 3.25 1.66669 4.16667L1.66669 15.8333C1.66669 16.75 2.41669 17.5 3.33335 17.5H10V15.8333L3.33335 15.8333L3.33335 4.16667Z"
          fill="#8F64F9"
        />
      </g>
    </svg>
  );
};
