import { useToken } from "./auth-hooks";
import { useMemo } from "react";
import { Configuration, TagsApi } from "../../generated/syncroom-api/src";

export const useTagsApi = () => {
  const token = useToken();
  return useMemo(() => {
    const config = new Configuration({
      headers: {
        Authorization: token ? `Bearer ${token}` : (undefined as any),
      },
    });
    return new TagsApi(config);
  }, [token]);
};
