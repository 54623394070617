import {
  Network,
  NetworkStatusEnum,
} from "../../../generated/syncroom-api/src";
import { Button, ButtonType } from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { TbGridDots } from "react-icons/tb";
import { ErrorBar } from "../../components/ErrorBar";

type Props = {
  network: Network;
};
export const NetworkCard = ({ network }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className="group min-w-60 rounded-lg border border-zinc-200 bg-white p-7 hover:bg-gray-100"
      role="button"
      tabIndex={0}
      onClick={() => navigate(network.id)}
      onKeyUp={(event) => {
        if (event.key === "Enter" || event.key === " ") navigate(network.id);
      }}
    >
      <div className="flex h-full flex-col gap-2">
        <div className="flex justify-between">
          <h4 className="heading-4 mb-2.5 overflow-hidden text-ellipsis text-nowrap text-left">
            {network.name}
          </h4>
          <div className="bg-primary-clear_purple flex h-fit items-center rounded-lg px-3 py-1.5 font-bold">
            <TbGridDots />
            <span className="ml-1 block">{network.totalNodes}</span>
          </div>
        </div>
        <div className="mb-7 line-clamp-4">
          <h6 className="font-bold">Purpose</h6>
          {network.purpose}
        </div>
        <div className="mt-auto flex flex-col gap-5">
          {network.status === NetworkStatusEnum.ERROR && (
            <ErrorBar totalErrors={network.totalErrors} />
          )}
          <Button type={ButtonType.TERTIARY}>View Network</Button>
        </div>
      </div>
    </div>
  );
};
