import { Modal } from "../../../components/Modal";
import { useNetworksApi } from "../../../shared/useNetworksApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { FormCheckboxInput } from "../../../components/form/FormCheckboxInput";
import { Button, ButtonType } from "../../../components/buttons/Button";
import {
  AddNodeAndConnectionsToNetwork200ResponseInner,
  Neighbour,
  ResponseError,
} from "../../../../generated/syncroom-api/src";
import { FormSelectInput } from "../../../components/form/FormSelectInput";
import { SelectOption } from "../../../components/select/SelectWrapper";
import { handleResponseError } from "../../../util/errors";
import { ErrorBanner } from "../../../components/banner/ErrorBanner";
import { Loader } from "../../../components/Loader";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import { NodesAddedStepForSetNetworkForConnectedNode } from "./NodesAddedStepForSetNetworkForConnectedNode";

type Props = {
  nodeUrl: string;
  nodeName: string;
  neighbour: Neighbour;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
  onSave: () => void;
};

export type FormValues = {
  assignedNetwork: string;
  centrallyManaged: boolean;
};

export const SetNetworkForConnectedNodeModal = ({
  setIsModalOpen,
  nodeUrl,
  nodeName,
  neighbour,
  onClose,
  onSave,
}: Props) => {
  const [error, setError] = useState<string>();
  const api = useNetworksApi();
  const { networkId } = useParams();
  const [step, setStep] = useState<number>(1);
  const [submittedNetwork, setSubmittedNetwork] = useState<string>();
  const getNetworksQuery = useQuery({
    queryKey: ["networks"],
    queryFn: () => api.getNetworks(),
  });

  const addConnectionToNetworkValidationSchema = yup.object({
    assignedNetwork: yup.string().required("Please select a network"),
    centrallyManaged: yup.boolean().required(),
  });

  const addNodeConnectionToNetworkRequest = useMutation<
    AddNodeAndConnectionsToNetwork200ResponseInner[],
    ResponseError,
    FormValues
  >({
    mutationFn: (values: FormValues) => {
      setSubmittedNetwork(values.assignedNetwork);
      return api.addNodeAndConnectionsToNetwork({
        networkId: values.assignedNetwork,
        addNodeAndConnectionsToNetworkRequest: {
          url: nodeUrl,
          name: nodeName,
          neighbours: [neighbour],
          centrallyManaged: values.centrallyManaged,
        },
      });
    },
    onSuccess: () => {
      setStep(2);
    },
    onError: (error) => handleResponseError(error, (msg) => setError(msg)),
  });

  if (getNetworksQuery.isPending) return <Loader />;
  if (getNetworksQuery.isError)
    return (
      <ResponseErrorMessage error={getNetworksQuery.error}>
        Error fetching networks
      </ResponseErrorMessage>
    );
  const networks = getNetworksQuery.data;

  const networkOptions: SelectOption[] = networks.map((network) => ({
    label: network.name,
    value: network.id,
  }));

  return (
    <Formik
      initialValues={{
        assignedNetwork: networkId,
        centrallyManaged: true,
      }}
      validationSchema={addConnectionToNetworkValidationSchema}
      onSubmit={async (values) => {
        addNodeConnectionToNetworkRequest.mutate(
          await addConnectionToNetworkValidationSchema.validate(values),
        );
      }}
    >
      <Form noValidate className="grid gap-y-4">
        <Modal
          isLoading={addNodeConnectionToNetworkRequest.isPending}
          toggleModal={setIsModalOpen}
          title="Set Network for connected node"
          buttons={
            step === 1 ? (
              <>
                <Button type={ButtonType.SECONDARY} onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button buttonType="submit">Add node to network</Button>
              </>
            ) : (
              <Button
                type={ButtonType.PRIMARY}
                onClick={() => {
                  onClose();
                  onSave();
                }}
              >
                Return to network
              </Button>
            )
          }
        >
          {step === 1 && (
            <div className="space-y-4">
              {error && <ErrorBanner>{error}</ErrorBanner>}
              <FormSelectInput
                name="assignedNetwork"
                options={networkOptions}
                placeholder="Choose a network"
                isSearchable={false}
                label="Assign to network"
              />
              <FormCheckboxInput
                label="Connection managed centrally"
                name="centrallyManaged"
              />
            </div>
          )}
          {step === 2 && (
            <NodesAddedStepForSetNetworkForConnectedNode
              results={addNodeConnectionToNetworkRequest.data!}
              networkId={submittedNetwork!}
              nodeName={nodeName}
              nodeUrl={nodeUrl}
            />
          )}
        </Modal>
      </Form>
    </Formik>
  );
};
