import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AddNodeAndConnectionsToNetwork200ResponseInner,
  Neighbour,
  ResponseError,
} from "../../../../generated/syncroom-api/src";
import { Modal } from "../../../components/Modal";
import { useState } from "react";
import { useNetworksApi } from "../../../shared/useNetworksApi";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { SelectNeighbours } from "./SelectNeighbours";
import { FormCheckboxInput } from "../../../components/form/FormCheckboxInput";
import { FormTagsEditor } from "../../../components/form/FormTagsEditor";
import { FormTextInput } from "../../../components/form/FormTextInput";
import { handleResponseError } from "../../../util/errors";
import { ErrorBanner } from "../../../components/banner/ErrorBanner";
import { NodesAddedStepForAddNodeModal } from "./NodesAddedStepForAddNodeModal";

type Props = {
  setIsModalOpen: (value: boolean) => void;
  networkId: string;
  onClose: () => void;
};

export type FormValues = {
  neighboursFetched: boolean;
  name: string;
  url: string;
  tags: string[];
  neighbours: Neighbour[];
  centrallyManaged: boolean;
};

export const AddNodeModal = ({ setIsModalOpen, networkId, onClose }: Props) => {
  const [error, setError] = useState<string>();
  const [step, setStep] = useState<number>(1);
  const queryClient = useQueryClient();
  const api = useNetworksApi();

  const validationScheme = yup.object({
    neighboursFetched: yup.boolean().required(),
    name: yup.string().required().trim().default(""),
    url: yup
      .string()
      .trim()
      .url()
      .required("Exalate URL is a required field to find neighbouring nodes"),
    tags: yup.array().optional().default([]),
    neighbours: yup
      .array()
      .when("neighboursFetched", {
        is: true,
        then: (schema) =>
          schema.min(1, "Atleast one neighbour should be selected"),
        otherwise: (schema) =>
          schema.min(
            1,
            "First find neighbours and select at least one before submitting",
          ),
      })
      .required(),
    centrallyManaged: yup.boolean().required(),
  });

  const addNodeAndConnectionsToNetwork = useMutation<
    AddNodeAndConnectionsToNetwork200ResponseInner[],
    ResponseError,
    FormValues
  >({
    mutationFn: (values: FormValues) =>
      api.addNodeAndConnectionsToNetwork({
        networkId,
        addNodeAndConnectionsToNetworkRequest: {
          name: values.name,
          url: values.url,
          tags: values.tags,
          neighbours: values.neighbours,
          centrallyManaged: values.centrallyManaged,
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["networks", networkId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["nodes"],
      });
      setStep(2);
    },
    onError: (error) => handleResponseError(error, (msg) => setError(msg)),
  });

  return (
    <Formik
      initialValues={{
        neighboursFetched: false,
        name: "",
        url: "",
        tags: [],
        neighbours: [],
        centrallyManaged: true,
      }}
      validationSchema={validationScheme}
      onSubmit={async (values) => {
        addNodeAndConnectionsToNetwork.mutate(
          await validationScheme.validate(values),
        );
      }}
    >
      <Form>
        <Modal
          toggleModal={setIsModalOpen}
          title="Add node"
          isLoading={addNodeAndConnectionsToNetwork.isPending}
          buttons={
            step === 1 ? (
              <>
                <Button type={ButtonType.SECONDARY} onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button buttonType="submit">Add</Button>
              </>
            ) : (
              <Button type={ButtonType.PRIMARY} onClick={() => onClose()}>
                Return to network
              </Button>
            )
          }
        >
          {step === 1 && (
            <div className="space-y-5">
              {error && <ErrorBanner>{error}</ErrorBanner>}
              <FormTextInput label="Name" name="name" />
              <FormTextInput
                label="Exalate URL"
                name="url"
                placeholder="https://jcloudnode-abcd-efgh-ijkm-lmno.exalate.cloud"
              />
              <FormTagsEditor label="Tags (optional)" name="tags" />
              <SelectNeighbours networkId={networkId} setError={setError} />
              <FormCheckboxInput
                label="Manage node in SyncRoom"
                name="centrallyManaged"
              />
            </div>
          )}
          {step === 2 && (
            <NodesAddedStepForAddNodeModal
              results={addNodeAndConnectionsToNetwork.data!}
              networkId={networkId}
            />
          )}
        </Modal>
      </Form>
    </Formik>
  );
};
