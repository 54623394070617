import { useEffect } from "react";

// Global counter to track open modals
let openModalCount = 0;

export const usePreventScrollingOutsideModal = () => {
  return useEffect(() => {
    openModalCount++;
    document.body.style.overflow = "hidden";
    return () => {
      openModalCount--;
      if (openModalCount === 0) {
        document.body.style.overflow = "unset";
      }
    };
  }, []);
};
