import { Button, ButtonSize, ButtonType } from "../components/buttons/Button";

type Props = {
  title: string;
  text: string;
  hasButton?: boolean;
  onClick?: () => void;
  buttonText?: string;
};

export const EmptyStatePage = ({
  title,
  text,
  hasButton = false,
  onClick,
  buttonText,
}: Props) => {
  if (hasButton) {
    if (buttonText === undefined) {
      throw new Error("hasButton is true but no buttonText was given.");
    }

    if (onClick === undefined) {
      throw new Error("hasButton is true but no onClick was given.");
    }
  } else {
    if (buttonText !== undefined) {
      throw new Error("buttonText is given but hasButton is false.");
    }

    if (onClick !== undefined) {
      throw new Error("onClick is given but hasButton is false.");
    }
  }

  return (
    <div className="flex h-full flex-col items-center justify-center gap-10">
      <div className="flex flex-col gap-4 text-center">
        <h1 className="heading-1">{title}</h1>
        <p>{text}</p>
      </div>
      {hasButton && (
        <Button
          type={ButtonType.PRIMARY}
          size={ButtonSize.DEFAULT}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
