import { useField } from "formik";
import {
  Props as WrapperProps,
  SelectOption,
  SelectWrapper,
} from "../select/SelectWrapper";
import { FormFieldError } from "./FormFieldError";
import { FormLabel } from "./FormLabel";

type Props = WrapperProps<false, string> & {
  label: string;
};

export const FormSelectInput = (props: Props) => {
  const [field, , helper] = useField(props.name);

  const options = props.options as SelectOption[];
  const value = options.find((o) => o.value === field.value);

  return (
    <div>
      <label>
        <FormLabel>{props.label}</FormLabel>
        <SelectWrapper
          {...field}
          {...props}
          onChange={(value, actionMeta) => {
            props.onChange?.(value, actionMeta);
            return helper.setValue(value?.value);
          }}
          value={value}
        ></SelectWrapper>
      </label>
      <FormFieldError name={props.name} />
    </div>
  );
};
