import { EmptyStatePage } from "./EmptyStatePage";

export const AccessDeniedPage = () => {
  return (
    <EmptyStatePage
      hasButton={false}
      title="Sorry, you have no permission to visit this page."
      text="You currently do not have access to this page. Please return to the network overview to select a different network."
    />
  );
};
