import { useNetworksApi } from "../shared/useNetworksApi";
import { useQuery } from "@tanstack/react-query";

export const useNetworkInfoQuery = (networkId: string) => {
  const api = useNetworksApi();

  return useQuery({
    queryKey: ["networks", networkId],
    queryFn: () =>
      api.getNetworkInfo({
        networkId,
      }),
  });
};
