import { EmptyStatePage } from "./EmptyStatePage";

export const NotFoundPage = () => {
  return (
    <EmptyStatePage
      hasButton={false}
      title="This page doesn't exist"
      text="The page you are looking for might have been removed or is temporarily unavailable."
    />
  );
};
