import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNetworksApi } from "../../shared/useNetworksApi";
import { Form, Formik } from "formik";
import { Modal } from "../../components/Modal";
import { Button, ButtonType } from "../../components/buttons/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleResponseError } from "../../util/errors";
import { ErrorBanner } from "../../components/banner/ErrorBanner";

type Props = {
  networkId: string;
  networkName: string;
  toggleModal: () => void;
};

export const RemoveNetworkModal = ({
  networkId,
  networkName,
  toggleModal,
}: Props) => {
  const api = useNetworksApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string>();

  const removeNetworkQuery = useMutation({
    mutationFn: (networkId: string) =>
      api.deleteNetwork({
        networkId,
      }),
    onSuccess: async () => {
      // Remove everything, because we don't know which nodes are affected by this delete
      // Also use remove instead of invalidate, to avoid 404 errors, because invalidate triggers a refresh
      queryClient.removeQueries();
      navigate("/networks");
    },
    onError: async (error) => {
      handleResponseError(error, (errorMessage) =>
        setErrorMessage(errorMessage),
      );
    },
  });

  return (
    <Formik
      initialValues={{ networkId }}
      onSubmit={async (values) => {
        removeNetworkQuery.mutate(values.networkId);
      }}
    >
      <Form>
        <Modal
          title="Remove network"
          toggleModal={toggleModal}
          isLoading={removeNetworkQuery.isPending}
          buttons={
            <>
              <Button onClick={() => toggleModal()} type={ButtonType.SECONDARY}>
                Cancel
              </Button>
              <Button buttonType="submit">Submit</Button>
            </>
          }
        >
          <div className="space-y-5">
            {errorMessage && (
              <ErrorBanner>
                Error occurred while removing network: {errorMessage}
              </ErrorBanner>
            )}
            <p className="mb-4">
              You are about to remove the <strong>{networkName}</strong> network
              from SyncRoom. You can now manage connections in this network
              directly via the Exalate app installed in your instance.
            </p>
            <p>
              <strong>Warning</strong>: This action is irreversible.
            </p>
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
