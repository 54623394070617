import React from "react";
import { ToggleBase } from "../ToggleBase";

type Props = {
  state?: boolean;
  setState: (value: boolean) => void;
  disabled?: boolean;
};

// Please note that the state of the toggle must be managed by the parent component as it is the easiest way to get the
// state out of the toggle component
export const Toggle = ({ state, setState, disabled = false }: Props) => {
  const checkHandler = () => {
    setState(!state);
  };

  return (
    <ToggleBase
      disabled={disabled}
      input={{
        onChange: checkHandler,
        name: "defaultToggle",
        value: state,
        onBlur: () => {},
        checked: state,
      }}
    />
  );
};
