import {
  Issue,
  Node,
  StartTestRunIncoming200ResponseInner,
  StartTestRunOutgoing200ResponseInner,
} from "../../../../../generated/syncroom-api/src";
import { TicketSelection } from "./TicketSelection";
import { useState } from "react";
import { Replica } from "./Replica";
import { ReplicaTitle, TitleType } from "./ReplicaTitle";

type Props = {
  sourceNode: Node;
  targetNode: Node;
  outgoingResults: StartTestRunOutgoing200ResponseInner[];
  incomingResults: StartTestRunIncoming200ResponseInner[];
  issueSelection: Issue[];
};

export const TestRunResults = ({
  sourceNode,
  targetNode,
  issueSelection,
  outgoingResults,
  incomingResults,
}: Props) => {
  const [selectedIssue, setSelectedIssue] = useState(issueSelection[0]);
  const selectedIncomingResultIsPresent = incomingResults.some(
    (issue) => issue.sourceIssueUrn === selectedIssue.issueUrn,
  );

  return (
    <div>
      <TicketSelection
        issues={issueSelection}
        selectedIssue={selectedIssue}
        setSelectedIssue={setSelectedIssue}
      />
      <div className="flex space-x-5 p-5">
        <div className="w-1/2 space-y-4">
          <Replica
            node={sourceNode}
            issueUrn={selectedIssue.issueUrn}
            type={TitleType.INCOMING}
            result={outgoingResults.find(
              (issue) => issue.issueUrn === selectedIssue.issueUrn,
            )}
          />
        </div>
        <div className="w-1/2 space-y-4">
          {selectedIncomingResultIsPresent ? (
            <Replica
              node={targetNode}
              issueUrn={
                incomingResults.find(
                  (result) => result.sourceIssueUrn === selectedIssue.issueUrn,
                )?.targetIssueUrn
              }
              type={TitleType.OUTGOING}
              result={incomingResults.find(
                (issue) => issue.sourceIssueUrn === selectedIssue.issueUrn,
              )}
            />
          ) : (
            <ReplicaTitle
              type={TitleType.NO_RESPONSE}
              node={targetNode}
              issueUrn={undefined}
              errorPresent={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
