import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app/App";
import "./app/styles/globals.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { AppAuthentication } from "./app/AppAuthentication";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defaultOnError } from "./app/util/errors";
import { ResponseError } from "./generated/syncroom-api/src";

const oidcConfig: AuthProviderProps = {
  authority: "/auth/realms/" + window.env.REACT_APP_KEYCLOAK_REALM,
  client_id: window.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: window.location.href,
  onSigninCallback: () => {
    const url = new URL(window.location.href);

    // remove auth params from url
    url.searchParams.delete("state");
    url.searchParams.delete("session_state");
    url.searchParams.delete("iss");
    url.searchParams.delete("code");

    window.history.replaceState({}, document.title, url);
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      retry: (retryCount, error) => {
        if (retryCount > 3) {
          return false;
        }

        if (error.name === "ResponseError") {
          const responseError = error as ResponseError;
          return !(
            responseError.response.status === 404 ||
            responseError.response.status === 403
          );
        }

        return true;
      },
    },
    mutations: {
      onError: (error) => defaultOnError(error),
    },
  },
});

const root = createRoot(document.getElementById("app")!);
root.render(
  <React.StrictMode>
    <ToastContainer />
    <QueryClientProvider client={queryClient}>
      <AuthProvider {...oidcConfig}>
        <AppAuthentication>
          <App />
        </AppAuthentication>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
