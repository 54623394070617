import React, { Suspense } from "react";
import { useQuery } from "@tanstack/react-query";
import { LoadingPage } from "../../../components/LoadingPage";
import { WarningBanner } from "../../../components/banner/WarningBanner";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useNodeInfoQuery } from "../../../nodes/useNodeInfoQuery";

const ScriptEditorCore = React.lazy(() => import("./ScriptEditorCoreModule"));

type Props = {
  nodeId: string;
  script: string;
  onChange: (script: string) => void;
  error?: string;
};

export const ScriptEditor = ({ nodeId, script, onChange, error }: Props) => {
  const api = useNodeApi();
  const nodeInfo = useNodeInfoQuery(nodeId);

  const ideSuggestions = useQuery({
    queryKey: ["ideSuggestions", nodeId],
    queryFn: () =>
      api.getIdeSuggestions({
        nodeId,
      }),
    retry: false,
    enabled: window.env.REACT_APP_EDITOR_SUGGESTIONS_ENABLED === "true",
  });

  const isLoading = nodeInfo.isLoading || ideSuggestions.isLoading;
  const suggestions = {
    ...(ideSuggestions.data ?? { suggestions: [] }),
    isLoading: isLoading,
  };

  return (
    <div className="flex flex-grow flex-col justify-stretch">
      {ideSuggestions.isError && (
        <div className="p-5">
          <WarningBanner>
            Failed to fetch suggestions from the node, suggestions are disabled.
          </WarningBanner>
        </div>
      )}
      {!isLoading && (
        <Suspense fallback={<LoadingPage />}>
          <ScriptEditorCore
            theme="exalateTheme"
            value={script}
            onChange={(change: string) => onChange(change)}
            language={`groovy_${nodeId}`}
            nodeType={nodeInfo.data?.type}
            ideSuggestions={suggestions}
            height="100%"
            options={{
              minimap: { enabled: false },
            }}
            error={error}
          />
        </Suspense>
      )}
    </div>
  );
};
