import { PageHeader } from "../components/layout/PageHeader";
import { Card } from "../components/layout/Card";
import { useUserApi } from "../shared/useUserApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Loader } from "../components/Loader";
import { ResponseErrorMessage } from "../components/ResponseErrorMessage";
import { EmptyStatePage } from "../pages/EmptyStatePage";
import { UserRoleChip } from "./UserRoleChip";
import { Chip, ChipColor } from "../components/Chip";
import { Link } from "react-router-dom";
import { UserRoleEnum, User } from "../../generated/syncroom-api/src";
import { Button, ButtonSize, ButtonType } from "../components/buttons/Button";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import { Roles } from "../shared/Roles";
import { CreateEditUserModal } from "./CreateEditUserModal";

export const UserListViewPage = () => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const api = useUserApi();
  const queryClient = useQueryClient();
  const queryKey = ["users"];

  const getAllUsersRequests = useQuery({
    queryKey,
    queryFn: () => api.getUsers(),
  });

  if (getAllUsersRequests.isPending) return <Loader />;
  if (getAllUsersRequests.isError)
    return (
      <ResponseErrorMessage error={getAllUsersRequests.error}>
        Error fetching users in SyncRoom
      </ResponseErrorMessage>
    );

  const users = getAllUsersRequests.data;

  if (users.length <= 0)
    return (
      <EmptyStatePage title="No users found" text="Users should be added" />
    );

  return (
    <>
      {isCreateUserModalOpen && (
        <CreateEditUserModal
          selectedUser={selectedUser}
          onClose={() => {
            setSelectedUser(undefined);
            setIsCreateUserModalOpen(false);
          }}
          onSave={async () => {
            await queryClient.invalidateQueries({ queryKey });
            setSelectedUser(undefined);
            setIsCreateUserModalOpen(false);
          }}
        />
      )}
      <PageHeader>
        <h1 className="heading-1">Users overview</h1>
      </PageHeader>
      <Card
        header="All users"
        buttons={
          <Button
            onClick={() => setIsCreateUserModalOpen(true)}
            size={ButtonSize.MEDIUM}
            iconLeft={<FaPlus />}
          >
            Add user
          </Button>
        }
      >
        <table>
          <thead>
            <tr>
              <th className="w-80">Email</th>
              <th className="w-40">Role</th>
              <th className="w-full">Allowed networks</th>
              <th className="w-20"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.email}>
                  <td>{user.email}</td>
                  <td>
                    <UserRoleChip role={user.role} />
                  </td>
                  <td>
                    {user.role === UserRoleEnum.SYSTEM_ADMIN ? (
                      <p>All networks are allowed for a System Admin</p>
                    ) : (
                      <div className="my-auto flex flex-wrap gap-2">
                        {user.assignedNetworks.map((network) => {
                          return (
                            <Link
                              to={`/networks/${network.networkId}`}
                              key={user.email + network.networkId}
                            >
                              <Chip color={ChipColor.GRAY}>
                                {network.networkName}
                              </Chip>
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </td>
                  <td>
                    {user.role !== Roles.SYSTEM_ADMIN && (
                      <Button
                        iconLeft={<FaPencil />}
                        size={ButtonSize.SMALL}
                        type={ButtonType.SECONDARY}
                        onClick={() => {
                          setSelectedUser(user);
                          setIsCreateUserModalOpen(true);
                        }}
                        label={`Edit user ${user.email}`}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </>
  );
};
