import CreatableSelect from "react-select/creatable";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";
import { useMemo } from "react";
import { useTagsApi } from "../../../shared/useTagsApi";
import { FormFieldError } from "../../../components/form/FormFieldError";

type Props = {
  name: string;
};

interface SelectOption {
  value: string;
  label: string;
}

export const NodeTagsEditor = ({ name }: Props) => {
  const [field, _, helper] = useField<string[]>(name);
  const api = useTagsApi();
  const tags = useQuery({
    queryKey: ["tags"],
    queryFn: () => api.getTags(),
  });

  const tagOptions: SelectOption[] = useMemo(() => {
    return (tags.data || []).map((tag) => ({
      value: tag,
      label: tag,
    }));
  }, [tags.data]);

  const currentSelected: SelectOption[] = useMemo(() => {
    return field.value.map((item) => ({ value: item, label: item }));
  }, [field.value]);

  return (
    <div>
      <CreatableSelect
        {...field}
        className="form-field-select w-full"
        options={tagOptions}
        isMulti={true}
        isClearable={false}
        onChange={(newvalue) =>
          helper.setValue(
            newvalue
              .filter((i) => i.value.trim())
              .map((item) => item.value.trim()),
          )
        }
        value={currentSelected}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
      <FormFieldError name={field.name} />
    </div>
  );
};
