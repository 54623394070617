import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import React from "react";
import { BaseErrorModal } from "../../../components/errorModal/BaseErrorModal";

type Props = {
  nodeId: string;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
};

export const NodeErrorModal = ({ nodeId, setIsModalOpen, onClose }: Props) => {
  const api = useNodeApi();

  const nodeErrorsQuery = useQuery({
    queryKey: ["errors", nodeId],
    queryFn: () =>
      api.getErrorsFromNode({
        nodeId,
        offset: 0,
        limit: 100,
      }),
  });

  return (
    <BaseErrorModal
      response={nodeErrorsQuery.data}
      isLoading={nodeErrorsQuery.isPending}
      error={nodeErrorsQuery.error}
      setIsModalOpen={setIsModalOpen}
      onClose={onClose}
    />
  );
};
