/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAssignedNetworksInner
 */
export interface UserAssignedNetworksInner {
    /**
     * The unique identifier of the network the user has access to.
     * @type {string}
     * @memberof UserAssignedNetworksInner
     */
    networkId: string;
    /**
     * 
     * @type {string}
     * @memberof UserAssignedNetworksInner
     */
    networkName: string;
}

/**
 * Check if a given object implements the UserAssignedNetworksInner interface.
 */
export function instanceOfUserAssignedNetworksInner(value: object): value is UserAssignedNetworksInner {
    if (!('networkId' in value) || value['networkId'] === undefined) return false;
    if (!('networkName' in value) || value['networkName'] === undefined) return false;
    return true;
}

export function UserAssignedNetworksInnerFromJSON(json: any): UserAssignedNetworksInner {
    return UserAssignedNetworksInnerFromJSONTyped(json, false);
}

export function UserAssignedNetworksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssignedNetworksInner {
    if (json == null) {
        return json;
    }
    return {
        
        'networkId': json['networkId'],
        'networkName': json['networkName'],
    };
}

export function UserAssignedNetworksInnerToJSON(value?: UserAssignedNetworksInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'networkId': value['networkId'],
        'networkName': value['networkName'],
    };
}

