import { ReactNode } from "react";
import { Breadcrumbs } from "../../breadcrumbs/Breadcrumbs";

type Props = {
  children: ReactNode;
  grid?: boolean;
};

export const PageHeader = ({ children, grid = false }: Props) => {
  return (
    <div className={`mb-10 ${grid ? "grid grid-cols-2 gap-y-5" : ""}`}>
      <Breadcrumbs />
      {children}
    </div>
  );
};
