import Select, {
  DropdownIndicatorProps,
  GroupBase,
  Props as SelectProps,
} from "react-select";
import { SelectDropdownIndicator } from "./SelectDropdownIndicator";
import { ReactNode } from "react";

export interface SelectOption<TOptionValue = string> {
  value: TOptionValue;
  label: ReactNode;
}

export type Props<IsMulti extends boolean, TOptionValue> = SelectProps<
  SelectOption<TOptionValue>,
  IsMulti,
  GroupBase<SelectOption<TOptionValue>>
> & {
  name: string;
};

const DropdownIndicator = (
  props: DropdownIndicatorProps<SelectOption<any>>,
) => <SelectDropdownIndicator props={props} />;

export const SelectWrapper = <
  IsMulti extends boolean = false,
  TOptionValue = string,
>({
  ...props
}: Props<IsMulti, TOptionValue>) => {
  return (
    <Select
      {...props}
      components={{ DropdownIndicator }}
      unstyled={true}
      classNames={{
        control: ({ isFocused, isDisabled }) =>
          `h-12 border hover:cursor-pointer rounded-lg bg-neutral-lesser_white px-4 ${isDisabled ? "text-gray-400" : ""} ${isFocused ? "border-primary ring ring-primary-lesser_purple" : "border-neutral_lesser_grey"}}`,
        menu: () =>
          `mt-2 border border-neutral_lesser_grey bg-white rounded-lg shadow`,
        option: () =>
          `border-b p-3.5 hover:bg-gray-200 hover:cursor-pointer whitespace-nowrap`,
        indicatorSeparator: () => `bg-gray-200 mx-4 ml-6`,
        multiValue: () => `rounded-lg bg-sky-100 px-2 text-blue-500 mr-1`,
      }}
      menuPortalTarget={document.body}
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
};
