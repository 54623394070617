/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Response object for errors on a connection. Includes the name of the node it originated on.
 * @export
 * @interface NodeErrorWithOriginNode
 */
export interface NodeErrorWithOriginNode {
    /**
     * The identifier of a specific error on a node
     * @type {number}
     * @memberof NodeErrorWithOriginNode
     */
    id: number;
    /**
     * The stacktrace of the error
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    stacktrace: string;
    /**
     * Time in milliseconds in UTC timezone
     * @type {number}
     * @memberof NodeErrorWithOriginNode
     */
    creationTime: number;
    /**
     * 
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    type: NodeErrorWithOriginNodeTypeEnum;
    /**
     * The root cause of the error
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    rootCause?: string;
    /**
     * Details about the root cause
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    rootCauseDetails?: string;
    /**
     * The local issue URN to identify which issue causes the error
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    localIssueUrn?: string;
    /**
     * The remote issue URN to identify which issue causes the error
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    remoteIssueUrn?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeErrorWithOriginNode
     */
    originNodeName?: string;
}


/**
 * @export
 */
export const NodeErrorWithOriginNodeTypeEnum = {
    NODE: 'NODE',
    RELATION: 'RELATION',
    TRIGGER: 'TRIGGER',
    ISSUE: 'ISSUE',
    WARNING: 'WARNING'
} as const;
export type NodeErrorWithOriginNodeTypeEnum = typeof NodeErrorWithOriginNodeTypeEnum[keyof typeof NodeErrorWithOriginNodeTypeEnum];


/**
 * Check if a given object implements the NodeErrorWithOriginNode interface.
 */
export function instanceOfNodeErrorWithOriginNode(value: object): value is NodeErrorWithOriginNode {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('stacktrace' in value) || value['stacktrace'] === undefined) return false;
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function NodeErrorWithOriginNodeFromJSON(json: any): NodeErrorWithOriginNode {
    return NodeErrorWithOriginNodeFromJSONTyped(json, false);
}

export function NodeErrorWithOriginNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeErrorWithOriginNode {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stacktrace': json['stacktrace'],
        'creationTime': json['creationTime'],
        'type': json['type'],
        'rootCause': json['rootCause'] == null ? undefined : json['rootCause'],
        'rootCauseDetails': json['rootCauseDetails'] == null ? undefined : json['rootCauseDetails'],
        'localIssueUrn': json['localIssueUrn'] == null ? undefined : json['localIssueUrn'],
        'remoteIssueUrn': json['remoteIssueUrn'] == null ? undefined : json['remoteIssueUrn'],
        'originNodeName': json['originNodeName'] == null ? undefined : json['originNodeName'],
    };
}

export function NodeErrorWithOriginNodeToJSON(value?: NodeErrorWithOriginNode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'stacktrace': value['stacktrace'],
        'creationTime': value['creationTime'],
        'type': value['type'],
        'rootCause': value['rootCause'],
        'rootCauseDetails': value['rootCauseDetails'],
        'localIssueUrn': value['localIssueUrn'],
        'remoteIssueUrn': value['remoteIssueUrn'],
        'originNodeName': value['originNodeName'],
    };
}

