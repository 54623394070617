import React, { useState } from "react";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { Trigger } from "../../../../generated/syncroom-api/src";
import { DeleteTriggerModal } from "./DeleteTriggerModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import { Modal } from "../../../components/Modal";
import { saveAs } from "file-saver";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../../../components/ActionsMenu";
import { FaFileDownload } from "react-icons/fa";

type Props = {
  nodeId: string;
  connectionId: string;
  selectedVersionId: string;
  isActive: boolean;
  trigger: Trigger;
  onRefresh: () => void;
};

export const TriggerActionMenu = ({
  nodeId,
  connectionId,
  selectedVersionId,
  isActive,
  trigger,
  onRefresh,
}: Props) => {
  const api = useNodeApi();
  const queryClient = useQueryClient();
  const [isDeleteTriggerModalOpen, setIsDeleteTriggerModalOpen] =
    useState(false);
  const [showBulkExalateModal, setShowBulkExalateModal] = useState(false);
  const [showBulkUnExalateModal, setShowBulkUnExalateModal] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const issuesUnderTrigger = useQuery({
    queryKey: [
      "nodes",
      nodeId,
      "connections",
      connectionId,
      "versions",
      selectedVersionId,
      "triggers",
      trigger.id,
      "issues",
    ],
    queryFn: () =>
      api.getIssuesUnderTrigger({
        nodeId,
        connectionId,
        versionId: selectedVersionId,
        triggerId: trigger.id!,
      }),
    enabled: showBulkExalateModal || showBulkUnExalateModal,
  });

  const bulkExalate = useMutation({
    mutationFn: () =>
      api.triggerBulkExalate({
        nodeId,
        connectionId,
        triggerId: trigger.id!,
        versionId: selectedVersionId,
      }),
    onSuccess: () => setShowBulkExalateModal(false),
  });

  const bulkUnExalate = useMutation({
    mutationFn: () =>
      api.triggerBulkUnExalate({
        nodeId,
        connectionId,
        triggerId: trigger.id!,
        versionId: selectedVersionId,
      }),
    onSuccess: () => setShowBulkUnExalateModal(false),
  });

  const buttons: ButtonMenuItem[] = [
    {
      buttonText: "Remove",
      onClick: () => setIsDeleteTriggerModalOpen(true),
      disabled: isActive,
      showButton: true,
    },
    {
      buttonText: "Bulk Exalate",
      onClick: () => setShowBulkExalateModal(true),
      disabled: !isActive,
      showButton: window.env.REACT_APP_BULK_EXALATE_ENABLED === "true",
    },
    {
      buttonText: "Bulk Unexalate",
      onClick: () => setShowBulkUnExalateModal(true),
      disabled: !isActive,
      showButton: window.env.REACT_APP_BULK_EXALATE_ENABLED === "true",
    },
  ];

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const data = await queryClient.fetchQuery({
        queryKey: [
          "nodes",
          nodeId,
          "connections",
          connectionId,
          "versions",
          selectedVersionId,
          "triggers",
          trigger.id,
          "issues",
          "download",
        ],
        queryFn: () =>
          api.downloadIssuesUnderTrigger({
            nodeId,
            connectionId,
            versionId: selectedVersionId,
            triggerId: trigger.id!,
          }),
      });
      saveAs(data, `${trigger.id}.csv`);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      return (
        <ResponseErrorMessage
          error={new Error("Error downloading issues under trigger")}
        >
          Error downloading issues under trigger
        </ResponseErrorMessage>
      );
    }
  };

  return (
    <div>
      {isDeleteTriggerModalOpen && (
        <DeleteTriggerModal
          triggerId={trigger.id!}
          nodeId={nodeId}
          connectionId={connectionId}
          versionId={selectedVersionId}
          onClose={() => {
            setIsDeleteTriggerModalOpen(false);
            onRefresh();
          }}
        />
      )}
      {showBulkExalateModal && (
        <Modal
          isLoading={
            issuesUnderTrigger.isLoading || bulkExalate.isPending || downloading
          }
          toggleModal={() => setShowBulkExalateModal(false)}
          title="Bulk Exalate"
          buttons={
            <>
              <Button
                type={ButtonType.SECONDARY}
                iconLeft={<FaFileDownload />}
                onClick={() => handleDownload()}
              >
                Download
              </Button>
              <Button
                type={ButtonType.SECONDARY}
                onClick={() => setShowBulkExalateModal(false)}
              >
                Cancel
              </Button>
              <Button onClick={() => bulkExalate.mutate()}>Bulk Exalate</Button>
            </>
          }
        >
          <div>
            You are about to start the synchronization of{" "}
            {issuesUnderTrigger.data} issues via connection. It will take some
            time to synchronize {issuesUnderTrigger.data} issues in bulk.
            Validate the configuration before you start. You can test it by
            syncing 1 issue before you Bulk Exalate {issuesUnderTrigger.data}{" "}
            issues.
          </div>
        </Modal>
      )}
      {showBulkUnExalateModal && (
        <Modal
          isLoading={issuesUnderTrigger.isLoading || bulkUnExalate.isPending}
          toggleModal={() => setShowBulkUnExalateModal(false)}
          title="Bulk Unexalate"
          buttons={
            <>
              <Button
                type={ButtonType.SECONDARY}
                onClick={() => setShowBulkUnExalateModal(false)}
              >
                Cancel
              </Button>
              <Button onClick={() => bulkUnExalate.mutate()}>
                Bulk Unexalate
              </Button>
            </>
          }
        >
          <div>
            You are about to Unexalate the synchronization of{" "}
            {issuesUnderTrigger.data} issues. Exalate will stop the
            synchronization of these issues and clean the sync metadata.
          </div>
        </Modal>
      )}
      <ActionsMenu
        buttons={buttons}
        type={ButtonType.TERTIARY}
        size={ActionMenuSize.DEFAULT}
      />
    </div>
  );
};
