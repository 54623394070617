import { Modal } from "../../../components/Modal";
import { useState } from "react";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ResponseError } from "../../../../generated/syncroom-api/src";
import { useNodeApi } from "../../../shared/useNodeApi";
import { handleResponseError } from "../../../util/errors";
import { ErrorBanner } from "../../../components/banner/ErrorBanner";

type Props = {
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
  nodeId: string;
  connectionId: string;
  versionId: string;
};

export const ActivateVersionModal = ({
  setIsModalOpen,
  onClose,
  nodeId,
  connectionId,
  versionId,
}: Props) => {
  const [error, setError] = useState<string>();
  const api = useNodeApi();
  const queryClient = useQueryClient();

  const activateVersionMutate = useMutation<void, ResponseError>({
    mutationFn: () =>
      api.activateVersion({
        nodeId,
        connectionId,
        versionId,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["nodes", nodeId, "connections", connectionId],
      });
      onClose();
    },
    onError: (error) => handleResponseError(error, (msg) => setError(msg)),
  });

  return (
    <Modal
      toggleModal={setIsModalOpen}
      title="Activate version"
      buttons={
        <div className="flex gap-3">
          <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
            Cancel
          </Button>
          <Button onClick={() => activateVersionMutate.mutate()}>
            Confirm
          </Button>
        </div>
      }
      isLoading={activateVersionMutate.isPending}
    >
      {error && <ErrorBanner>{error}</ErrorBanner>}
      <p>You are about to activate this version.</p>
    </Modal>
  );
};
