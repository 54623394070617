import { useParams } from "react-router-dom";
import { ConnectionDetail } from "./ConnectionDetail";

export const ConnectionDetailPage = () => {
  const { nodeId, connectionId } = useParams();

  if (!nodeId || !connectionId) return <h2>Error</h2>;

  return <ConnectionDetail nodeId={nodeId} connectionId={connectionId} />;
};
